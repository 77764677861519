import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import createBrowserHistory from "history/createBrowserHistory";
import Header from "./Components/Header";
import Home from "./Pages/Home";
import ServicesOffered from "./Pages/Services/ServicesOffered/ServicesOffered";
import Works from "./Pages/Works/Works";
import Contact from "./Pages/Contact/Contact";
import AboutUs from "./Pages/About/About";
import Hero from "react-bulma-components/lib/components/hero";
import Content from "react-bulma-components/lib/components/content";
import Footer from "react-bulma-components/lib/components/footer";
import Container from "react-bulma-components/lib/components/container";
import NewWorks from "./Pages/NewWorks/NewWorks";

import "./App.css";
import KimUseCase from "./Pages/worksusecase/kimusecase";
import BakersWellUseCase from "./Pages/worksusecase/bakerswellusecase";
// import CmsUseCase from "./Pages/worksusecase/cmsusecase";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      history: createBrowserHistory()
    };
  }

  componentDidMount() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
  }

  render() {
    return (
      <Router history={this.state.history}>
        <Hero id="app" size="fullheight">
          <Hero.Head>
            <Header />
          </Hero.Head>
          <Hero.Body>
            <div id="app-container">
              <Switch>
                <Route exact path="/" component={Home} />
                {/* <Route exact path="/old" component={Home2} /> */}
                <Route exact path="/services" component={ServicesOffered} />
                <Route exact path="/works" component={Works} />
                <Route exact path="/new-works" component={NewWorks} />
                <Route exact path="/kimsusecase" component={KimUseCase} />
                {/* <Route exact path="/cmsusecase" component={CmsUseCase} /> */}
                <Route exact path="/bakerswellusecase" component={BakersWellUseCase} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/about" component={AboutUs} />
              </Switch>
            </div>
          </Hero.Body>
          <Hero.Footer>

            <Footer id="hero_footer">
              <Container>
                <Content textAlignment="centered">
                  <p>
                    Built with passion, <strong>Ezsofe</strong> 2019.
                  </p>
                </Content>
              </Container>
            </Footer>
          </Hero.Footer>
        </Hero>
      </Router>
    );
  }
}

export default App;
