import React from "react";
import "./ServicesOffered.css";
import Fade from "react-reveal/Fade";
import Tile from "react-bulma-components/lib/components/tile";

import { HashLink } from 'react-router-hash-link'
import Scrollspy from 'react-scrollspy'
import Slide from 'react-reveal/Slide'
import Worksbox from '../../../Components/worksbox/worksbox'
import Worksbutton from '../../../Components/worksbox/worksbutton'
import erp from '../../../assets/images/erp.png'
import rpa from '../../../assets/images/rpa.png'

// import Image from './Image/Image.js'
// import WorkModal from './WorkModal/WorkModal.js'

class ServicesOffered extends React.Component {
  componentWillMount() {
    console.dir(this.props.location)
    window.scrollTo(0, 0);
    this.location = "home";
    console.log("current location: " + this.location);
  }

  componentDidMount() {
      window.scrollTo(0, 0)

      // Add event listeners for scrolling
    //   window.addEventListener('wheel', this.onWheelScroll, false);
    //   window.addEventListener('keydown', this.onKeyDownScroll, false);

  }

  // Scrolling
  componentWillUnmount() {
      // Removes customer listener and re-enable default scrolling
    //   window.removeEventListener('wheel', this.onWheelScroll, false);
    //   window.addEventListener('keydown', this.onKeyDownScroll, false);
  }
  
  // componentDidMount() {
  //   window.scrollTo(0, 0);
  // }

  // componentWillUnmount() {
  //   window.removeEventListener("wheel", this.onWheelScroll, false);
  //   window.removeEventListener("keydown", this.onKeyDownScroll, false);
  // }

  // constructor(props) {
  //   super(props)
  //   this.state = {
  //       showModal: false,
  //       selectedWork: {},
  //       CES: [
  //           {
  //               id: 0,
  //               title: 'Title of Work',
  //               subtitle: 'Subtitle',
  //               details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
  //               link: 'www.google.com',
  //               year: '2019',
  //               headerImage: sc1,
  //               screenshots: [sc1, sc2, sc3]
  //           },
  //           {
  //               id: 1,
  //               title: 'Title of Work',
  //               subtitle: 'Subtitle',
  //               details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
  //               link: 'www.google.com',
  //               year: '2019',
  //               headerImage: sc1,
  //               screenshots: [sc1, sc2, sc3]
  //           }
  //       ],
  //       DEH: [
  //           {
  //               id: 0,
  //               title: 'Title of Work',
  //               subtitle: 'Subtitle',
  //               details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
  //               link: 'www.google.com',
  //               year: '2019',
  //               headerImage: sc1,
  //               screenshots: [sc1, sc2, sc3]
  //           },
  //           {
  //               id: 1,
  //               title: 'Title of Work',
  //               subtitle: 'Subtitle',
  //               details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
  //               link: 'www.google.com',
  //               year: '2019',
  //               headerImage: sc1,
  //               screenshots: [sc1, sc2, sc3]
  //           }
  //       ],
  //       Maintain: [
  //           {
  //               id: 0,
  //               title: 'Title of Work',
  //               subtitle: 'Subtitle',
  //               details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
  //               link: 'www.google.com',
  //               year: '2019',
  //               headerImage: sc3,
  //               screenshots: [sc1, sc2, sc3]
  //           },
  //           {
  //               id: 1,
  //               title: 'Title of Work',
  //               subtitle: 'Subtitle',
  //               details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
  //               link: 'www.google.com',
  //               year: '2019',
  //               headerImage: sc3,
  //               screenshots: [sc1, sc2, sc3]
  //           }
  //       ],
  //       sdp: [
  //           {
  //               id: 0,
  //               title: 'Title of Work',
  //               subtitle: 'Subtitle',
  //               details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
  //               link: 'www.google.com',
  //               year: '2019',
  //               headerImage: sc1,
  //               screenshots: [sc1, sc2, sc3]
  //           },
  //           {
  //               id: 1,
  //               title: 'SDP id 1',
  //               subtitle: 'Subtitle',
  //               details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
  //               link: 'www.google.com',
  //               year: '2019',
  //               headerImage: sc3,
  //               screenshots: [sc1, sc2, sc3]
  //           }
  //       ]
  //   }

  //   this.show = this.show.bind(this)
  //   this.close = this.close.bind(this)
  //   this.eachImage = this.eachImage.bind(this)
  //   this.renderModal = this.renderModal.bind(this)
  //   this.check = this.check.bind(this)

  //   /** Location */
  //   this.location = "home";
  //   console.log("Location is now " + this.location);
  //   // this.onWheelScroll = this.onWheelScroll.bind(this);

  //   /** Refs */
  //   this.rf_home = React.createRef();
  //   this.rf_solutions = React.createRef();
  //   this.rf_development = React.createRef();
  //   this.rf_maintenance = React.createRef();
  // }

  // check() {
  //     if (this.props.location.hash != null) {
  //         if (this.props.location.hash == "#pills-CES") {
  //             this.status = true;
  //             this.ces = " active show";
  //         }
  //         else if (this.props.location.hash == "#pills-DEH") {
  //             this.status = true;
  //             this.deh = " active show";
  //         }
  //         else if (this.props.location.hash == "#pills-Maintain") {
  //             this.status = true;
  //             this.M = " active show";
  //         }
  //         else if (this.props.location.hash == "#pills-all") {
  //             this.status = true;
  //             this.all = " active show";
  //         }
  //         else {
  //             this.status = false
  //             this.active = "";
  //         }
  //     }

  // }

  // show(obj) {
  //     this.setState({
  //         showModal: true,
  //         selectedWork: obj
  //     })
  // }

  // close() {
  //     this.setState({
  //         showModal: false,
  //         selectedWork: {}
  //     })
  // }

  // renderModal() {
  //     let obj = this.state.selectedWork
  //     let closeBtn = <button className="closeBtn" onClick={this.close}>Close</button>
  //     return (
  //         <WorkModal title={obj.title}
  //             subtitle={obj.subtitle}
  //             details={obj.details}
  //             link={obj.link}
  //             year={obj.year}
  //             headerImage={obj.headerImage}
  //             screenshots={obj.screenshots}
  //             closeBtn={closeBtn} />
  //     )
  // }

//   eachImage(obj, id) {
//       return (
//           <Image key={id} imgSrc={obj.headerImage} alt={obj.id}
//               clickFn={() => this.show(obj)} />
//       )
//   }

  renderWorks() {
    this.check()
    return (
        <section id="works" className="container">
            <h1 className="text-center">
                <span className="page-title-medium">Featured </span>
                <span className="page-title-reg">Works</span>
            </h1>

            <ul className="nav nav-pills mb-4 d-flex justify-content-center" id="pills-tab" role="tablist">
                <li className="nav-item">
                    <a className={'nav-link' + this.all} id="pills-all-tab" data-toggle="pill" href="#pills-all" role="tab" aria-controls="pills-all" aria-selected={this.status}>
                        All
                        </a>
                </li>
                <li className="nav-item">
                    <a className={'nav-link' + this.ces} id="pills-CES-tab" data-toggle="pill" href="#pills-CES" role="tab" aria-controls="pills-CES" aria-selected={this.status}>
                        Customised Enterprise Solution
                        </a>
                </li>
                <li className="nav-item">
                    <a className={'nav-link' + this.deh} id="pills-DEH-tab" data-toggle="pill" href="#pills-DEH" role="tab" aria-controls="pills-DEH" aria-selected={this.status}>
                        Development House
                        </a>
                </li>
                <li className="nav-item">
                    <a className={'nav-link' + this.M} id="pills-Maintain-tab" data-toggle="pill" href="#pills-Maintain" role="tab" aria-controls="pills-Maintain" aria-selected="false">
                        Maintainance
                        </a>
                </li>
                <li className="nav-item">
                    <a className={'nav-link' + this.sdp} id="pills-sdp-tab" data-toggle="pill" href="#pills-sdp" role="tab" aria-controls="pills-sdp" aria-selected="false">
                        Student Development Programme
                        </a>
                </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
                <div className={'tab-pane fade' + this.all} id="pills-all" role="tabpanel" aria-labelledby="pills-home-all">

                    <div className="content">
                        {this.state.CES.map(this.eachImage)}
                        {this.state.DEH.map(this.eachImage)}
                        {this.state.Maintain.map(this.eachImage)}
                        {this.state.sdp.map(this.eachImage)}
                    </div>
                </div>

                <div className={'tab-pane fade' + this.ces} id="pills-CES" role="tabpanel" aria-labelledby="pills-CES-tab">
                    <div className="content">
                        {this.state.CES.map(this.eachImage)}
                    </div>
                </div>

                <div className={'tab-pane fade' + this.deh} id="pills-DEH" role="tabpanel" aria-labelledby="pills-DEH-tab">
                    <div className="content">
                        {this.state.DEH.map(this.eachImage)}
                    </div>
                </div>

                <div className={'tab-pane fade' + this.M} id="pills-Maintain" role="tabpanel" aria-labelledby="pills-Maintain-tab">
                    <div className="content">
                        {this.state.Maintain.map(this.eachImage)}
                    </div>
                </div>

                <div className="tab-pane fade" id="pills-sdp" role="tabpanel" aria-labelledby="pills-sdp-tab">
                    <div className="content">
                        {this.state.sdp.map(this.eachImage)}
                    </div>
                </div>
            </div>
        </section>
    )
  }

  render() {
    const scrollWithOffset = (el, offset) => {
      const elementPosition = el.offsetTop - offset;
      window.scroll({
          top: elementPosition,
          left: 0,
          behavior: "smooth"
      })
    }

    return (
      <div id="works-div-render">
        <div id="header_wrapper">
          <Fade>
            <div id="header_intro">
              <br/><br/><br/><br/>
              <h1 className="title-big" id="title-services-offered"><strong>
                SERVICES OFFERED</strong>
              </h1>
            </div>
          </Fade>
        </div>

        {/* TOP NAVIGATION BAR */}
        <nav id="category-bar" style={{height: "80px"}}>
          <Scrollspy items={['solutions', 'integration', 'web', 'mobile', 'maintenance']} currentClassName="is-current"  offset={-300}>
              <li className="category-button" id="nav_solutions">
                  <HashLink smooth to="#solutions" scroll={el => scrollWithOffset(el, 126)}>
                      <span style={{ display: "inline-block", maxWidth: "350px", paddingTop: "10px" }}>Customised Enterprise Solutions</span>
                  </HashLink>
              </li>
              <li className="category-button" id="nav_integration">
                  <HashLink smooth to="#integration" scroll={el => scrollWithOffset(el, 126)}>
                      <span style={{ display: "inline-block", maxWidth: "350px", paddingTop: "10px" }}>Systems Integration</span>
                  </HashLink>
              </li>
              <li className="category-button" id="nav_web">
                  <HashLink smooth to="#web" scroll={el => scrollWithOffset(el, 126)}>
                      <span style={{ display: "inline-block", maxWidth: "350px", paddingTop: "10px" }}>Web Development</span>
                  </HashLink>
              </li>
              <li className="category-button" id="nav_mobile">
                  <HashLink smooth to="#mobile" scroll={el => scrollWithOffset(el, 126)}>
                      <span style={{ display: "inline-block", maxWidth: "350px", paddingTop: "10px" }}>Mobile Development</span>
                  </HashLink>
              </li>
              <li className="category-button" id="nav_maintenance">
                  <HashLink smooth to="#maintenance" scroll={el => scrollWithOffset(el, 126)}>
                      <span style={{ display: "inline-block", maxWidth: "350px", paddingTop: "10px" }}>Maintenance & Other Services</span>
                  </HashLink>
              </li>
              {/* <li className="category-button one-line"><HashLink smooth to="#integration" scroll={el => scrollWithOffset(el, 126)}>Systems Integration</HashLink></li>
              <li className="category-button one-line"><HashLink smooth to="#web" scroll={el => scrollWithOffset(el, 126)}>Web Development</HashLink></li>
              <li className="category-button one-line"><HashLink smooth to="#mobile" scroll={el => scrollWithOffset(el, 126)}>Mobile Development</HashLink></li>
              <li className="category-button one-line"><HashLink smooth to="#maintenance" scroll={el => scrollWithOffset(el, 126)}>Maintenance & Other Services</HashLink></li> */}
          </Scrollspy>
        </nav>

        {/* 1. CUSTOMIZED ENTERPRISE SOLUTIONS */}
        <section id="works" ref={this.rf_solutions}>
          <div className="container" id="solutions">
              <div className="tile is-ancestor is-12">
                  <div style={{ paddingBottom: "10px" }} className="tile row-title is-parent">
                      <div className="row-number"></div>
                      <Slide left cascade>
                        <span className="section-number">1</span>
                      </Slide>                      

                      {/* TITLE & SUBTITLE */}
                      <div className="tile is-5 row-right level">
                          <Slide right>
                              <div className="level-item">
                                  <h2 className="tile">
                                      <a style={{ paddingRight: "10px" }} className="anchor level-item" ></a>
                                      <div className="title-subtitle-wrapper">
                                          <span className="tile section-title">Customised Enterprise Solutions</span>
                                          <span className="tile is-12 section-subtitle">Custom-designed applications such as Enterprise Resource Planning (ERP) and Robotic Process Automation (RPA) systems.</span>
                                      </div>

                                  </h2>
                              </div>

                          </Slide>
                      </div>

                  </div>
              </div>

              {/* OUR WORKS BUTTON */}
              <div className="our-works-wrapper">
                <Fade bottom>
                
                    <Worksbutton pageURL={"/works#solutions"} message="Customized enterprise solutions works"></Worksbutton>
                
                </Fade>
              </div>
              <br/>

              { /* FEATURES */}
              <div className="tile is-ancestor is-12">
                  {/* ERP */}
                  <div className="tile is-parent is-6">
                      {/* space */}
                      <div class="tile is-1"/>

                      {/* ERP image */}
                      <div className="tile is-child is-4" style={{ paddingTop: "10px" }}>
                          <Fade bottom>
                            <img src={require("./../../../assets/images/erp.png")} className="icon" />
                          </Fade>
                      </div>

                      {/* space */}
                      <div class="tile is-1"/>

                      {/* ERP description */}
                      <div className="tile is-child is-6" style={{ paddingTop: "10px" }}>
                        <span className="tile is-12 section-subtitle"><strong>Enterprise Resource Planning (ERP)</strong></span>
                        <span className="tile is-12 section-subtitle">Integrating all facets of an operation into a single application.</span>
                      </div>

                      {/* <div style={{ paddingTop: "10px" }} className="tile is-child is-6">
                          <Fade bottom>
                              <Worksbox imageURL={cms} Descriptionheader={"CMS Logistics"} Description={"Custom Website for CMS"}></Worksbox>

                          </Fade>
                          <div className="container level our-works-wrapper">
                              <Fade bottom >
                                  <Worksbutton pageURL={"/cmsusecase"}></Worksbutton>
                              </Fade>
                          </div>
                      </div> */}
                  </div>

                  {/* RPA */}
                  <div className="tile is-parent is-6">
                      {/* space */}
                      <div class="tile is-1"/>

                      {/* RPA image */}
                      <div className="tile is-child is-4" style={{ paddingTop: "10px" }}>
                          <Fade bottom>
                            <img src={require("./../../../assets/images/rpa.png")} className="icon" />
                          </Fade>
                      </div>

                      {/* space */}
                      <div class="tile is-1"/>

                      {/* RPA description */}
                      <div className="tile is-child is-6" style={{ paddingTop: "10px" }}>
                        <span className="tile is-12 section-subtitle"><strong>Robotic Process Automation (RPA)</strong></span>
                        <span className="tile is-12 section-subtitle">Business process automation with artificial intelligence.</span>
                      </div>
                  </div>
              </div>
          </div>
        </section>

      <br /><br /><br/>

      {/* 2. SYSTEMS INTEGRATION */}
      <section id="works" ref={this.rf_development}>
        <div className="container" id="integration">
            <div className="tile is-ancestor is-12">
                <div style={{ paddingBottom: "20px" }} className="tile row-title is-parent">
                    <div className="row-number"></div>
                    <Slide left cascade>
                        <span className="section-number">2</span>
                    </Slide>

                    <div className="tile is-5 row-right level">
                        <Slide right>
                            <div className="level-item">
                                <h2 className="tile">
                                    <a className="anchor level-item" ></a>
                                    <div className="title-subtitle-wrapper">
                                        <span className="tile is-12 section-title">Systems Integration</span>
                                        <span className="tile is-12 section-subtitle">Linking together different computing systems </span>
                                        <span className="tile is-12 section-subtitle">and software applications as a coordinated whole.</span>
                                    </div>

                                </h2>
                            </div>

                        </Slide>
                    </div>

                </div>
            </div>

            {/* OUR WORKS BUTTON */}
            <div className="container level our-works-wrapper">
                <Fade bottom>
                    <Worksbutton pageURL={"/works#integration"} message="Systems integration works"></Worksbutton>
                </Fade>
            </div>
            <br/><br/>

            {/* FEATURE */}
            <div className="tile is-ancestor">

                <div className="tile is-parent">
                    {/* space */}
                    <div class="tile is-1"/>

                    {/* integration image */}
                    <div className="tile is-child is-2" style={{ paddingTop: "10px" }}>
                        <Fade bottom>
                            <img src={require("./../../../assets/images/integration.png")} className="icon" />
                        </Fade>
                    </div>

                    {/* space */}
                    <div class="tile is-1"/>

                    {/* integration description */}
                    <div className="tile is-child is-5" style={{ paddingTop: "10px" }}>
                        <br/>
                        <span className="tile is-12 section-subtitle"><strong>Seamless Assimilation & Transition</strong></span>
                        <span className="tile is-12 section-subtitle">Holistic amalgamation & aggregation to accomplish an overarching functionality that is more than the sum of its parts.</span>
                    </div>
                </div>

            </div>
        </div>
      </section>
      
      <br/><br/><br/>

      {/* 3. WEB DEVELOPMENT */}
      <section id="works" ref={this.rf_development}>
        <div className="container" id="web">
            <div className="tile is-ancestor is-12">
                <div style={{ paddingBottom: "20px" }} className="tile row-title is-parent">
                    <div className="row-number"></div>
                    <Slide left cascade>
                        <span className="section-number">3</span>
                    </Slide>
                    <div className="tile is-5 row-right level">
                        <Slide right>
                            <div className="level-item">
                                <h2 className="tile">
                                    <a className="anchor level-item" ></a>
                                    <div className="title-subtitle-wrapper">
                                        <span className="tile is-12 section-title">Web Development</span>
                                        <span className="tile is-12 section-subtitle">Company site, website revamp, e-commerce site</span>
                                    </div>

                                </h2>
                            </div>

                        </Slide>
                    </div>

                </div>
            </div>

            {/* OUR WORKS BUTTON */}
            <div className="container level our-works-wrapper">
                <Fade bottom>
                    <Worksbutton pageURL={"/works#web"} message="Web development works"></Worksbutton>
                </Fade>
            </div>
            <br/><br/>

            { /* FEATURES */}
            <div className="tile is-ancestor is-12">

                {/* COMPANY SITE */}
                <div className="tile is-parent is-4">
                    {/* space */}
                    <div class="tile is-1"/>

                    {/* COMPANY SITE image */}
                    <div className="tile is-child is-4" style={{ paddingTop: "10px" }}>
                        <Fade bottom>
                            <img src={require("./../../../assets/images/companysite.png")} className="icon" />
                        </Fade>
                    </div>

                    {/* space */}
                    <div class="tile is-1"/>

                    {/* COMPANY SITE description */}
                    <div className="tile is-child is-6" style={{ paddingTop: "10px" }}>
                        <span className="tile is-12 section-subtitle"><strong>Company Site</strong></span>
                        <span className="tile is-12 section-subtitle">Powerful, strategic and resplendent digital solutions.</span>
                    </div>
                </div>

                {/* WEBSITE REVAMP */}
                <div className="tile is-parent is-4">
                    {/* space */}
                    <div class="tile is-1"/>

                    {/* WEBSITE REVAMP image */}
                    <div className="tile is-child is-4" style={{ paddingTop: "10px" }}>
                        <Fade bottom>
                            <img src={require("./../../../assets/images/websiterevamp.png")} className="icon" />
                        </Fade>
                    </div>

                    {/* space */}
                    <div class="tile is-1"/>

                    {/* WEBSITE REVAMP description */}
                    <div className="tile is-child is-6" style={{ paddingTop: "10px" }}>
                        <span className="tile is-12 section-subtitle"><strong>Website Revamp</strong></span>
                        <span className="tile is-12 section-subtitle">Modern sites for the modern world, where technology meets finesse.</span>
                    </div>
                </div>

                {/* E-COMMERCE SITE */}
                <div className="tile is-parent is-4">
                    {/* space */}
                    <div class="tile is-1"/>

                    {/* E-COMMERCE SITE image */}
                    <div className="tile is-child is-4" style={{ paddingTop: "10px" }}>
                        <Fade bottom>
                            <img src={require("./../../../assets/images/e-commerce.png")} className="icon" />
                        </Fade>
                    </div>

                    {/* space */}
                    <div class="tile is-1"/>

                    {/* E-COMMERCE SITE description */}
                    <div className="tile is-child is-6" style={{ paddingTop: "10px" }}>
                        <span className="tile is-12 section-subtitle"><strong>E-COMMERCE SITE</strong></span>
                        <span className="tile is-12 section-subtitle">Enticing digital platforms for the 21st-century consumer.</span>
                    </div>
                </div>

            </div>

        </div>
      </section>

      <br/><br/><br/>

      {/* 4. MOBILE DEVELOPMENT */}
      <section id="works" ref={this.rf_development}>
        <div className="container" id="mobile">
            <div className="tile is-ancestor is-12">
                <div style={{ paddingBottom: "20px" }} className="tile row-title is-parent">
                    <div className="row-number"></div>
                    <Slide left cascade>
                        <span className="section-number">4</span>
                    </Slide>
                    <div className="tile is-5 row-right level">
                        <Slide right>
                            <div className="level-item">
                                <h2 className="tile">
                                    <a className="anchor level-item" ></a>
                                    <div className="title-subtitle-wrapper">
                                        <span className="tile is-12 section-title">Mobile Development</span>
                                        <span className="tile is-12 section-subtitle">We offer both Hybrid (React Native) and Native (iOS, Android) mobile application development.</span>
                                    </div>

                                </h2>
                            </div>

                        </Slide>
                    </div>

                </div>
            </div>

            {/* OUR WORKS BUTTON */}
            <div className="container level our-works-wrapper">
                <Fade bottom>
                    <Worksbutton pageURL={"/works#mobile"} message="Mobile development works"></Worksbutton>
                </Fade>
            </div>
            <br/><br/>

            {/* FEATURES */}
            <div className="tile is-ancestor is-12">

                {/* HYBRID (REACT NATIVE) */}
                <div className="tile is-parent is-4">
                    {/* space */}
                    <div class="tile is-1"/>

                    {/*  HYBRID (REACT NATIVE) image */}
                    <div className="tile is-child is-4" style={{ paddingTop: "10px" }}>
                        <Fade bottom>
                            <img src={require("./../../../assets/images/react-native.png")} className="icon" />
                        </Fade>
                    </div>

                    {/* space */}
                    <div class="tile is-1"/>

                    {/*  HYBRID (REACT NATIVE) description */}
                    <div className="tile is-child is-6" style={{ paddingTop: "10px" }}>
                        <span className="tile is-12 section-subtitle"><strong>Hybrid (React Native)</strong></span>
                        <span className="tile is-12 section-subtitle">A budget-friendly platform to accommodate to your needs.</span>
                    </div>
                </div>

                {/* NATIVE (IOS) */}
                <div className="tile is-parent is-4">
                    {/* space */}
                    <div class="tile is-1"/>

                    {/* NATIVE (IOS) image */}
                    <div className="tile is-child is-4" style={{ paddingTop: "10px" }}>
                        <Fade bottom>
                            <img src={require("./../../../assets/images/ios.png")} className="icon" />
                        </Fade>
                    </div>

                    {/* space */}
                    <div class="tile is-1"/>

                    {/* NATIVE (IOS) description */}
                    <div className="tile is-child is-6" style={{ paddingTop: "10px" }}>
                        <span className="tile is-12 section-subtitle"><strong>Native (iOS)</strong></span>
                        <span className="tile is-12 section-subtitle">Utilizing the Swift platform to serve iOS audiences.</span>
                    </div>
                </div>

                {/* NATIVE (ANDROID) SITE */}
                <div className="tile is-parent is-4">
                    {/* space */}
                    <div class="tile is-1"/>

                    {/* NATIVE (ANDROID) image */}
                    <div className="tile is-child is-4" style={{ paddingTop: "10px" }}>
                        <Fade bottom>
                            <img src={require("./../../../assets/images/android2.png")} className="icon" />
                        </Fade>
                    </div>

                    {/* space */}
                    <div class="tile is-1"/>

                    {/* NATIVE (ANDROID) description */}
                    <div className="tile is-child is-6" style={{ paddingTop: "10px" }}>
                        <span className="tile is-12 section-subtitle"><strong>Native (Android)</strong></span>
                        <span className="tile is-12 section-subtitle">Using Visual Studio Code to deliver your finest solutions.</span>
                    </div>
                </div>

            </div>

          </div>
      </section>

      <br/><br/><br/>

      {/* 5. MAINTENANCE & OTHER SERVICES */}
      <section id="works" ref={this.rf_development}>
        <div className="container" id="maintenance">
            <div className="tile is-ancestor is-12">
                <div style={{ paddingBottom: "20px" }} className="tile row-title is-parent">
                    <div className="row-number"></div>
                    <Slide left cascade>
                        <span className="section-number">5</span>
                    </Slide>
                    <div className="tile is-5 row-right level">
                        <Slide right>
                            <div className="level-item">
                                <h2 className="tile">
                                    <a className="anchor level-item" ></a>
                                    <div className="title-subtitle-wrapper">
                                        <span className="tile is-12 section-title">Maintenance & Other Services</span>
                                        <span className="tile is-12 section-subtitle">IT consultancy, email migration & hosting, web hosting, domain registration & management</span>
                                    </div>

                                </h2>
                            </div>

                        </Slide>
                    </div>

                </div>
            </div>

            {/* OUR WORKS BUTTON */}
            {/* <div className="container level our-works-wrapper">
                <Fade bottom>
                    <Worksbutton pageURL={"/works#maintenance"} message="Maintenance & other services works"></Worksbutton>
                </Fade>
            </div>
            <br/><br/> */}

            {/* FEATURES */}
            <div className="tile is-ancestor is-12">

                {/* IT CONSULTANCY */}
                <div className="tile is-parent is-4">
                    {/* space */}
                    <div class="tile is-1"/>

                    {/*  IT CONSULTANCY image */}
                    <div className="tile is-child is-4" style={{ paddingTop: "10px" }}>
                        <Fade bottom>
                            <img src={require("./../../../assets/images/consultancy.png")} className="icon" />
                        </Fade>
                    </div>

                    {/* space */}
                    <div class="tile is-1"/>

                    {/*  IT CONSULTANCY description */}
                    <div className="tile is-child is-6" style={{ paddingTop: "10px" }}>
                        <span className="tile is-12 section-subtitle"><strong>IT Consultancy</strong></span>
                        <span className="tile is-12 section-subtitle">Our expertise translates to your commercial success.</span>
                    </div>
                </div>

                {/* EMAIL HOSTING */}
                <div className="tile is-parent is-4">
                    {/* space */}
                    <div class="tile is-1"/>

                    {/* EMAIL HOSTING image */}
                    <div className="tile is-child is-4" style={{ paddingTop: "10px" }}>
                        <Fade bottom>
                            <img src={require("./../../../assets/images/email2.png")} className="icon" />
                        </Fade>
                    </div>

                    {/* space */}
                    <div class="tile is-1"/>

                    {/* EMAIL HOSTING description */}
                    <div className="tile is-child is-6" style={{ paddingTop: "10px" }}>
                        <span className="tile is-12 section-subtitle"><strong>Email hosting</strong></span>
                        <span className="tile is-12 section-subtitle">Simple and reliable email-hosting service.</span>
                    </div>
                </div>

                {/* WEB HOSTING */}
                <div className="tile is-parent is-4">
                    {/* space */}
                    <div class="tile is-1"/>

                    {/* WEB HOSTING image */}
                    <div className="tile is-child is-4" style={{ paddingTop: "10px" }}>
                        <Fade bottom>
                            <img src={require("./../../../assets/images/web.png")} className="icon" />
                        </Fade>
                    </div>

                    {/* space */}
                    <div class="tile is-1"/>

                    {/* WEB HOSTING description */}
                    <div className="tile is-child is-6" style={{ paddingTop: "10px" }}>
                        <span className="tile is-12 section-subtitle"><strong>Web Hosting</strong></span>
                        <span className="tile is-12 section-subtitle">Uplift your digital content with fast, reliable servers.</span>
                    </div>
                </div>

            </div>

          </div>
      </section>

    </div>
    );
  }
}

export default ServicesOffered;
