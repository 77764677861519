import React from "react";
import "./staff.css";

import Content from "react-bulma-components/lib/components/content";
import Heading from "react-bulma-components/lib/components/heading";

class Staff extends React.Component {
  render() {
    let { staff } = this.props;
    let { img, name, position, linkedIn } = staff;
    if(name.length > 12) {
      var marginBottom = "10px"
      var marginTop = "5px"
    } else {
      var marginBottom = "19px"
      var marginTop = "14px"
    }
    // var margin = name.length > 12 ? "5px" : "100px"

    return (
      <Content textAlignment="centered">
        <a href={linkedIn}>
          <img className="avatar" src={img} alt="Avatar" />
          <Heading
            size={6}
            textAlignment="centered"
            style={{ letterSpacing: 5, textIndent: 0, marginBottom: marginBottom, marginTop: marginTop }}
          >
            {name}
          </Heading>
          <span className="fab fa-linkedin" style={{ fontSize: "3em" }} />
          <br />
          <Heading
            size={6}
            subtitle
            renderAs="span"
            style={{ letterSpacing: 5, textIndent: 0 }}
          >
            {position}
          </Heading>
        </a>
      </Content>
    );
  }
}

export default Staff;
