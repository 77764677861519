export default [
  {
    title: "Mr Kin Lian Tan / Tan Kin Lian & Associates",
    para: "I have engaged Ezsofe for two projects involving the development of mobile apps. I found them to be competent"
    + " and have strong technical skills. I recommend Ezsofe for similar projects."
  },
  {
    title: "Mr Ong Kae Uei / Omark Worldwide",
    para: "It was a pleasant experience working with Ezsofe. They surprised me"
    + " with extraordinary experience and quality of work, kudos to Ezsofe."
  }
];