import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/ezsofe-logo-only.png";
import Navbar from "react-bulma-components/lib/components/navbar";
import Button from 'react-bulma-components/lib/components/button';
import "./styles.css";

const colors = {
  Default: '',
  primary: 'primary',
  info: 'info',
  danger: 'danger',
  warning: 'warning',
  success: 'success',
  white: 'white',
  black: 'black',
  light: 'light',
  dark: 'dark',
  link: 'link',
};



class Navigation extends React.Component {
  state = { isActive: false }

  toggleNav = () => {
    // this.setState(prevState => ({
    //   isActive: !prevState.isActive
    // }))
    this.setState({ isActive: !this.state.isActive })
  }



  render() {
    return (
      <Navbar className="navbar is-white " role="navigation" aria-label="main navigation" height="100px">
        <div className="navbar-brand">
          <a className="navbar-item navimg1" href="/">
            <img src={logo} height="50px"/>
          </a>

          <a role="button" className="navbar-burger burger" data-target="navMenu" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" onClick={this.toggleNav}>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div id="navbarBasicExample " className={this.state.isActive ? 'navbar-menu is-active' : 'navbar-menu'}>
          <div className="navbar-end">
            <Link to="/" className="navbar-item underline1" onClick={this.toggleNav}>
              Home

            </Link>
            <Link to="/about" className="navbar-item underline1" onClick={this.toggleNav}>
              About

            </Link>
            <Link to="/services" className="navbar-item underline1" onClick={this.toggleNav}>
              Services

            </Link>
            <Link to="/works" className="navbar-item underline1" onClick={this.toggleNav}>
              Works

            </Link>
            <Link to="/contact" className="navbar-item underline1" onClick={this.toggleNav}>
              Contact

            </Link>

          </div>
        </div>

      </Navbar>
    )
  }
};

export default Navigation;
