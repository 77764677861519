import React from "react";
import Slide from "../../../Components/Slide";

import "./styles.css";
import testimonials from "../../../data/testimonials";

import Tile from "react-bulma-components/lib/components/tile";

class Testimonials extends React.Component {
  constructor(props) {
    super(props);

    this.testimonialsArray = [];
    this.testimonialsArray = testimonials;
  }

  render() {
    return (
      <Slide id="testimonials" number={5} title="Testimonials" subtitle="Satisfied clients">
        <div className="container d-flex justify-content-center home-section-content">
          <Tile kind="ancestor">
            {this.testimonialsArray.map(testimonial => {
              return [
                <Tile key={testimonial.title}
                  size={5}
                  className="column-testimonial"
                  vertical
                  style={{ margin: "0 auto", padding: "24px" }}
                >
                  <h4 className="testimonial-title">{testimonial.title}</h4>
                  <p className="testimonial-para">{testimonial.para}</p>
                </Tile>
              ];
            })}
          </Tile>
        </div>
      </Slide>
    );
  }
}

export default Testimonials;
