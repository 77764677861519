import React from "react";
import Slide from "../../../Components/Slide";
import Vivus from "vivus";

import Tile from "react-bulma-components/lib/components/tile";

import planIcon from "../../../assets/images/wf_notepad.svg";
import buildIcon from "../../../assets/images/wf_web.svg";
import testIcon from "../../../assets/images/wf_conversation.svg";
// import iconArrow from "../../../assets/images/angle_arrow.svg";
import rocketIcon from "../../../assets/images/rocket.svg";
import testingIcon from "../../../assets/images/testing.svg";
import confirmIcon from "../../../assets/images/comfirm.svg";
import backupIcon from "../../../assets/images/backup.svg";


import colorline from "../../../assets/images/line.png";

import "./styles.css";

import Heading from "react-bulma-components/lib/components/heading";

class approach extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [
        <div className="item" key={1}>
          <div className="wf_image wf_svg" id="plan-svg" />

          <h4 className="wf_image_caption">STAGE A: PLAN</h4>

          <img src={colorline} className="colorline" />

          <div className="wf_stage_row">
            <h4 className="workflow-title desktop"> Requirements Gathering</h4>
          </div>

          <div className="wf_desc_row">
            <p className="workflow-para">
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </div>

          <div className="wf_stage_row">
            <h4 className="workflow-title desktop"> Prototype </h4>
          </div>

          <div className="wf_desc_row">
            <p className="workflow-para">
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </div>
        </div>,

        <div className="item" key={2}>
          <div className="wf_image wf_svg" id="build-svg" />

          <h4 className="wf_image_caption">STAGE B: BUILD</h4>
          <img src={colorline} className="colorline" />

          {/* <div className="wf_branches desktop" id="branchmiddle-svg" /> */}
          <div className="wf_stage_row">
            <h4 className="workflow-title desktop"> Development </h4>
          </div>

          <div className="wf_desc_row">
            <p className="workflow-para">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
        </div>,

        <div className="item" key={3}>
          <div className="wf_image wf_svg" id="test-svg" />

          <h4 className="wf_image_caption">STAGE C: TEST</h4>

          <img src={colorline} className="colorline" />

          {/* <div className="wf_branches desktop" id="branchsideright-svg" /> */}
          <div className="wf_stage_row">
            <h4 className="workflow-title desktop">
              {" "}
              User Acceptance Testing{" "}
            </h4>
          </div>

          <div className="wf_desc_row">
            <p className="workflow-para">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>

          <div className="wf_stage_row">
            <h4 className="workflow-title desktop"> Handover </h4>
          </div>

          <div className="wf_desc_row">
            <p className="workflow-para">
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </div>
        </div>
      ]
    };
  }

  componentDidMount() {
    new Vivus("plan-icon", {
      duration: 250,
      file: planIcon,
      type: "sync",
      start: "inViewport"
    });
    new Vivus("rocket-icon", {
      duration: 250,
      file: rocketIcon,
      type: "sync",
      start: "inViewport"
    });

    new Vivus("backup-icon", {
      duration: 250,
      file: backupIcon,
      type: "sync",
      start: "inViewport"
    });
    new Vivus("build-icon", {
      duration: 250,
      file: buildIcon,
      type: "sync",
      start: "inViewport"
    });

    new Vivus("test-icon", {
      duration: 250,
      file: testIcon,
      type: "sync",
      start: "inViewport"
    });
    new Vivus("confirm-icon", {
      duration: 100,
      file: confirmIcon,
      type: "sync",
      start: "inViewport"
    });

  }

  render() {
    return (
      <Slide id="approach" number={3} title="Approach" subtitle="How we do it" >
        <Tile id="approach-wrapper" textAlignment="centered" kind="ancestor" vertical>
          <Tile kind="parent" size={12} className="item approach-stage" key={1} id="row">
            
            <Tile kind="child" size={1}/>            
            
            {/* STAGE 1 */}
            <Tile kind="child" size={2} className="stages">
              <Tile className="wf_image wf_svg" id="plan-icon" />
              <h4 className="wf_image_caption">STAGE 1</h4>
              <img src={colorline} className="colorline" />

              <div className="wf_stage_row">
                <Heading size={5} style={{ letterSpacing: 1, textIndent: 0 }} className="heading">Requirements Gathering</Heading>
              </div>

              {/* DESCRIPTION 1 */}
              <div className="wf_description_1">
                <Heading
                  renderAs="p"
                  size={6}
                  subtitle
                  style={{ letterSpacing: 0, textIndent: 0, textAlign: "center" }}
                  className="description"
                >
                  We gather the needs of clients and explore IT solutions to complement operations.
                </Heading>
              </div>

            </Tile>
            <br/><br/>

            <Tile kind="child" size={2}/>

            {/* STAGE 2 */}
            <Tile kind="child" size={2} className="stages">
              <div className="wf_image wf_svg" id="confirm-icon" />
              <h4 className="wf_image_caption">STAGE 2</h4>
              <img src={colorline} className="colorline" />
              <div className="wf_stage_row">
                <Heading size={5} style={{ letterSpacing: 1, textIndent: 0 }} className="heading">Project Confirmation</Heading>
              </div>

              {/* DESCRIPTION 2 */}
              <div className="wf_description_2">
                <Heading
                    renderAs="p"
                    size={6}
                    subtitle
                    style={{ letterSpacing: 0, textIndent: 0 }}
                    className="description"
                  >
                  A quotation and proposed framework will be delivered for consideration.
                </Heading>
              </div>
            </Tile>

            <br/><br/>

            <Tile kind="child" size={2}/>

            {/* STAGE 3 */}
            <Tile kind="child" size={2} className="stages">
              <div className="wf_image wf_svg" id="build-icon" />

              <h4 className="wf_image_caption">STAGE 3</h4>

              <img src={colorline} className="colorline" />

              <div className="wf_stage_row">
                <Heading size={5} style={{ letterSpacing: 1, textIndent: 0 }} className="heading">Prototype Development</Heading>
              </div>

              {/* DESCRIPTION 3 */}
              <div className="wf_description_3">
                <Heading
                  renderAs="p"
                  size={6}
                  subtitle
                  style={{ letterSpacing: 0, textIndent: 0 }}
                  className="description"
                >
                  Upon confirmation, a prototype is presented to ensure agreement on objectives.
                </Heading>
              </div>
            </Tile>

            <br/>

            <Tile kind="child" size={1}/>

          </Tile>
          {/* END OF 1ST PARENT TILE */}

          {/* 4,5,6 */}
          <Tile kind="parent" size={12} className="item" key={3} id="row">
            
            <Tile kind="child" size={1}/>            
            
            {/* STAGE 4 */}
            <Tile kind="child" size={2} className="stages">
              <Tile className="wf_image wf_svg" id="rocket-icon" />
              <h4 className="wf_image_caption">STAGE 4</h4>
              <img src={colorline} className="colorline" />

              <div className="wf_stage_row">
                <Heading size={5} style={{ letterSpacing: 1, textIndent: 0 }} className="heading">
                  Project Development Initation
                </Heading>
              </div>

              {/* DESCRIPTION 4 */}
              <div className="wf_description_4">
                <Heading
                  renderAs="p"
                  size={6}
                  subtitle
                  style={{ letterSpacing: 0, textIndent: 0 }}
                  className="description"
                >
                  Upon clients' approval, we begin deployment of our proposed solution.
                </Heading>
              </div>
            </Tile>

            <br/><br/>

            <Tile kind="child" size={2} />

            {/* stage 5 */}
            <Tile kind="child" size={2} className="stages">
              <div className="wf_image wf_svg" id="test-icon" />
              <h4 className="wf_image_caption">STAGE 5</h4>
              <img src={colorline} className="colorline" />
              <div className="wf_stage_row">
                <Heading size={5} style={{ letterSpacing: 1, textIndent: 0 }} className="heading">
                  User Acceptance Testing
                </Heading>
              </div>

              {/* DESCRIPTION 5 */}
              <div className="wf_description_5">
                <Heading
                  renderAs="p"
                  size={6}
                  subtitle
                  style={{ letterSpacing: 0, textIndent: 0 }}
                  className="description"
                >
                  Upon completion, we conduct a UAT with client to review the product.
                </Heading>
              </div>
            </Tile>

            <br/><br/>

            <Tile kind="child" size={2}/>

            {/* STAGE 6 */}
            <Tile kind="child" size={2} className="stages">
              <div className="wf_image wf_svg" id="backup-icon" />

              <h4 className="wf_image_caption">STAGE 6</h4>

              <img src={colorline} className="colorline" />

              <div className="wf_stage_row">
                <Heading size={5} style={{ letterSpacing: 1, textIndent: 0 }} className="heading">
                  Project Handover & Completion
                </Heading>
              </div>

              {/* DESCRIPTION 6 */}
              <div className="wf_description_5">
                <Heading
                  renderAs="p"
                  size={6}
                  subtitle
                  style={{ letterSpacing: 0, textIndent: 0 }}
                  className="description"
                >
                  we hand over the project and begin maintenance (if applicable).
                </Heading>
              </div>
            </Tile>

            <Tile kind="child" size={1}/>

          </Tile>
          {/* END OF 2ND PARENT TILE */}

        </Tile>
      </Slide >
    );
  }
}

export default approach;














