import React from "react";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import Slide from "../../../Components/Slide";

import staff from "../../../data/staff";

import Staff from "../../../Components/staff";

import Content from "react-bulma-components/lib/components/content";
import Columns from "react-bulma-components/lib/components/columns";
import Button from "react-bulma-components/lib/components/button";

import "./styles.css";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      currentId: null,
      bio: staff
    };
  }

  render() {
    return (
      <Slide id="about" number={1} title="About Us" subtitle="Who we are">
        <Columns>
          <Columns.Column size={6}>
            <Fade top>
              <h3>
                <span
                  className="content-title-light"
                  style={{ paddingLeft: "30px" }}
                >
                  Our{" "}
                </span>
                <span className="content-title"> vision</span>
              </h3>
              <p
                className="content-para text-justify about-content-item"
                style={{ fontSize: "20px" }}
              >
                To create a better everyday with IT for everyone.
              </p>
            </Fade>
            <Fade bottom>
              <h3>
                <span
                  className="content-title-light"
                  style={{ paddingLeft: "30px" }}
                >
                  Our{" "}
                </span>
                <span className="content-title"> Mission</span>
              </h3>
              <p
                className="content-para text-justify about-content-item"
                style={{ fontSize: "20px" }}
              >
                To provide end-to-end, value-driven solutions that drives
                business process excellence with technology innovation as its
                core foundation to our clients.
              </p>
            </Fade>
            <br />
            <Content textAlignment="centered">
              <Button outlined renderAs={Link} to="/about">
                Meet The Team
              </Button>
            </Content>
          </Columns.Column>

          <Columns.Column size={6}>
            <Columns>
              <Columns.Column size={4}>
                <Staff staff={this.state.bio[0]} />
              </Columns.Column>
              <Columns.Column size={4}>
                <Staff staff={this.state.bio[1]} />
              </Columns.Column>
            </Columns>
          </Columns.Column>
        </Columns>
      </Slide>
    );
  }
}

export default About;
