export default [
  {
    key: 0,
    name: "Lukas Ng",
    position: "COO",
    img: require("../assets/images/Lukas Square.jpg"),
    linkedIn: "https://www.linkedin.com/in/lukas-ng-567a0b146/",
    email: "lukas@ezsofe.com",
    details:
      "This is details of Lukas Ng Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie."
  },
  {
    key: 1,
    name: "Aow Jia Rong",
    position: "CEO",
    img: require("../assets/images/aow_jiarong.jpg"),
    linkedIn: "https://www.linkedin.com/in/aowjiarong/",
    email: "jiarong@ezsofe.com",
    details:
      "This is details of Aow Jia Rong Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie."
  }
];
