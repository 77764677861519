import React from "react";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";

import Hero from "react-bulma-components/lib/components/hero";
import Heading from "react-bulma-components/lib/components/heading";
import Container from "react-bulma-components/lib/components/container";

import "./styles.css";

class Landing extends React.Component {
  render() {
    return (
      <Hero id="landing" size="fullheight">
        <Hero.Body>
          <Container fluid textAlignment="centered">
            <Slide bottom>
              <Heading subtitle>Welcome to</Heading>
              <br />
              <Heading size={4} className="title" >
                Ezsofe
              </Heading>
            </Slide>
            <br />
            <Fade>
              <hr style={{ color: "black", backgroundColor: "black" }} />
              <Heading subtitle>Optimizing your capabilities</Heading>
            </Fade>
          </Container>
        </Hero.Body>
      </Hero>
    );
  }
}
export default Landing;
