import React from 'react'
// import './usecase1.css'
import styled from 'styled-components';
import { string } from 'postcss-selector-parser';

export default class Usecase1 extends React.Component {

  render() {

    const Img = styled.img`
        width: 80%;
        height:300px;
        position: relative;
        overflow: hidden;
        margin-right:10%;
        border-radius: 8%;
        box-shadow: 10px 10px 5px grey;
        font-family: "Times New Roman", Times, serif;
}
        
        `;
    const Container = styled.div`
    padding-left:1%;
    
        
    `
    return (
      // <Container>
      <div class="columns">
        <div class="column is-2 "></div>
        <div class="column is-4  "  ><Img src={this.props.imageURL} />
        </div>
        <div class="column is-4">
          <div>
            <h2>Challenges Faced</h2>
          </div>
          <div col-sm-12>
            {this.props.challenge}
          </div>
          <div>
            <p><br /></p>
          </div>
          <div>
            {this.props.challenge2}
          </div>
        </div>
        <div class="column is-2"></div>


      </div>
      // </Container>

    )
  }
}
