import React from "react";
import Slide from "../../../Components/Slide";
import Zoom from 'react-reveal/Zoom'
import Fade from 'react-reveal/Fade'
import { Link } from 'react-router-dom'
import { Columns, Tile } from 'react-bulma-components'

import "./styles.css";

class Works extends React.Component {
  render() {
    return (
      <Slide id="works" number={4} title="Works" subtitle="Whom we've helped" style={{ height: "100%" }}>
        <Columns>
          <Columns.Column />
          <Columns.Column size={10} className="home-section-content">
            <div className="row client-content-row">
              <div className="col-md-12 col-sm-12">
                <Zoom>
                  <div className="row" style={{ marginTop: "15px", marginBottom: "15px" }}>
                    <div className="col-md-12 col-sm-12" style={{ padding: "0" }}>
                      <h5 style={{ textAlign: "center", fontSize: "20px", letterSpacing: "2px" }} className="content-title-light">
                        We rely on our extensive passion to ensure that we deliver success to our clients.
                      </h5>
                    </div>
                  </div>
                </Zoom>
              </div>
            </div>

            <div>

              {/* 6 logos */}
              <div className="row vertical-align-middle client-content-row" id="home-works-content">
                <Columns id="column">

                  {/* kim */}
                  <Columns.Column size={4} className="individual">
                    <Tile vertical>
                      <Tile kind="parent">
                        <Zoom><img src={require('../../../assets/images/Kim.png')} alt="Kim" height="50px" width="auto" className="image"/></Zoom>
                      </Tile>
                      <Tile kind="parent">
                        <Fade><h1 style={{ fontSize: "16px", margin: "6px, 0, 3px, 0" }} className="content-title text-center"> KIM Transport Solutions </h1></Fade>
                      </Tile>
                    </Tile>
                  </Columns.Column>

                  <br/>

                  {/* sim ge */}
                  <Columns.Column size={4} className="individual">
                    <Tile vertical>
                      <Tile kind="parent">
                        <Zoom><img src={require('../../../assets/images/simge.png')} alt="SIM Global Education" height="60px" width="auto" className="image"/></Zoom>
                      </Tile>
                      <Tile kind="parent">
                        <Fade><h1 style={{ fontSize: "16px", margin: "6px, 0, 3px, 0" }} className="content-title text-center">SIM Global Education</h1></Fade>
                      </Tile>
                    </Tile>
                  </Columns.Column>

                  <br/>

                  <Columns.Column size={4} className="individual">
                    <Tile vertical>
                      <Tile kind="parent">
                        <Zoom><img src={require('../../../assets/images/bakerswell.png')} alt="bakerswell" height="40px" width="auto" className="image" /></Zoom>
                      </Tile>
                      <Tile kind="parent">
                        <Fade><h1 style={{ fontSize: "16px", margin: "6px, 0, 3px, 0" }} className="content-title text-center"> Baker's Well </h1></Fade>
                      </Tile>
                    </Tile>
                  </Columns.Column>

                  <br/>

                  <Columns.Column size={4} className="individual">
                    <Tile vertical>
                      <Tile kind="parent">
                        <Zoom><img src={require('../../../assets/images/starHub-logo.png')} alt="Starhub" height="40px" width="auto" className="image" /></Zoom>
                      </Tile>
                      <Tile kind="parent">
                        <Fade><h1 style={{ fontSize: "16px", margin: "6px, 0, 3px, 0" }} className="content-title text-center"> Starhub </h1></Fade>
                      </Tile>
                    </Tile>
                  </Columns.Column>

                  <br/>

                  <Columns.Column size={4} className="individual">
                    <Tile vertical>
                      <Tile kind="parent">
                        <Zoom><img src={require('../../../assets/images/nyp.png')} alt="Nanyang Polytechnic" height="40px" width="auto" className="image"/></Zoom>
                      </Tile>
                      <Tile kind="parent">
                        <Fade><h1 style={{ fontSize: "16px", margin: "6px, 0, 3px, 0" }} className="content-title text-center"> Nanyang Polytechnic </h1></Fade>
                      </Tile>
                    </Tile>
                  </Columns.Column>

                  <br/>
                  
                  <Columns.Column size={4} className="individual">
                    <Tile vertical>
                      <Tile kind="parent">
                        <Zoom><img src={require('../../../assets/images/scs_logo.png')} alt="Singapore Computer Society" height="40px" width="auto" className="image"/></Zoom>
                      </Tile>
                      <Tile kind="parent">
                      <Fade><h1 style={{ fontSize: "16px", margin: "6px, 0, 3px, 0" }} className="content-title text-center"> Singapore Computer Society </h1></Fade>
                      </Tile>
                    </Tile>
                  </Columns.Column>
                </Columns>
              </div>

              {/* our works button */}
              <Fade>
                <Columns className="row client-content-btn d-flex justify-content-center">
                  <Columns.Column />
                  <Columns.Column size={3}>
                    <Link to="/works" className="button-link" style={{ display: "block", margin: "auto", textAlign: "center" }}>Our Works</Link>
                  </Columns.Column>
                  <Columns.Column />
                </Columns>
              </Fade>
            </div>

          </Columns.Column>
          <Columns.Column />
        </Columns>

      </Slide>

    );
  }
}

export default Works;
