import React from "react";
import Vivus from "vivus";
import Slide from "../../../Components/Slide";
import Columns from "react-bulma-components/lib/components/columns";
import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";
import Container from "react-bulma-components/lib/components/container";
import Tile from "react-bulma-components/lib/components/tile";

import agileSoftwareDevelopmentIcon from "../../../assets/images/phone.svg";
import customEnterpriseSolutionIcon from "../../../assets/images/development.svg";
import maintenanceIcon from "../../../assets/images/settings.svg";
import webDevelopmentIcon from "../../../assets/images/WebDevelopement.svg";
import mobileDevelopmentIcon from "../../../assets/images/AppDevelopement.svg";

import "./styles.css";

class ServicesOffered extends React.Component {
  componentDidMount() {
    new Vivus("custom-enterprise-solution-icon", {
      duration: 250,
      file: customEnterpriseSolutionIcon,
      type: "sync",
      start: "inViewport"
    });
    new Vivus("agile-software-development-icon", {
      duration: 250,
      file: agileSoftwareDevelopmentIcon,
      type: "sync",
      start: "inViewport"
    });
    new Vivus("web-development-icon", {
      duration: 250,
      file: webDevelopmentIcon,
      type: "sync",
      start: "inViewport"
    });
    new Vivus("mobile-development-icon", {
      duration: 250,
      file: mobileDevelopmentIcon,
      type: "sync",
      start: "inViewport"
    });
    new Vivus("maintenance-icon", {
      duration: 250,
      file: maintenanceIcon,
      type: "sync",
      start: "inViewport"
    });
  }

  render() {
    return (
      <Slide id="services" number={2} title="Services" subtitle="What we do">
        <Columns className="all">

          {/* customized enterprise solutions */}
          <Columns.Column className="individual">
            <Box textAlignment="centered" className="box">
              {/* <Container fluid id="custom-enterprise-solution-icon" /> */}
              <Tile className="wf_image wf_svg" id="custom-enterprise-solution-icon" />
              <div style={{ padding: "3px" }}>
                <img src={require("./../../../assets/images/line.png")} />
              </div>
              <Heading size={6} style={{ letterSpacing: 1, textIndent: 0, paddingBottom: "20px"}} className="service_name">
                Customised Enterprise solutions
              </Heading>
              <Heading
                renderAs="p"
                size={6}
                subtitle
                style={{ letterSpacing: 0, textIndent: 0 }}
                className="description"
              >
                Enterprise Resource Planning (ERP) solutions, specially
                customized to fit your business processes.
              </Heading>
            </Box>
          </Columns.Column>

          {/* systems integration */}
          <Columns.Column className="individual">
            <Box textAlignment="centered">
              {/* <Container fluid id="agile-software-development-icon" /> */}
              <Tile className="wf_image wf_svg" id="agile-software-development-icon" />
              <div style={{ padding: "3px" }}>
                <img src={require("./../../../assets/images/line.png")} />
              </div>
              <Heading size={6} style={{ letterSpacing: 1, textIndent: 0, textAlign: "center", paddingBottom: "20px"}} className="service_name">
                SYSTEMS INTEGRATION
              </Heading>
              <Heading
                renderAs="p"
                size={6}
                subtitle
                style={{ letterSpacing: 0, textIndent: 0 }}
                className="description"
              >
                Digitalize, automate, optimize & reorganize your business workflow and functions to achieve seamless transformation & holistic deployment
              </Heading>
            </Box>
          </Columns.Column>

          {/* web dev */}
          <Columns.Column className="individual">
            <Box textAlignment="centered">
              {/* <Container fluid id="web-development-icon" /> */}
              <Tile className="wf_image wf_svg" id="web-development-icon" />
              <div style={{ padding: "3px" }}>
                <img src={require("./../../../assets/images/line.png")} />
              </div>
              <Heading size={6} style={{ letterSpacing: 1, textIndent: 0, paddingBottom: "20px", textAlign: "center" }} className="service_name">
                Web Development
              </Heading>
              <Heading
                renderAs="ul"
                size={6}
                subtitle
                style={{ letterSpacing: 0, textIndent: 0 }}
                className="column-services"
                style={{textAlign: "left"}}
              >
                <li><Heading
                renderAs="p"
                size={6}
                subtitle
                style={{ letterSpacing: 0, textIndent: 0 }}
              >Company Site</Heading></li>
                <li><Heading
                renderAs="p"
                size={6}
                subtitle
                style={{ letterSpacing: 0, textIndent: 0 }}
              >Website Revamp</Heading></li>
                <li><Heading
                renderAs="p"
                size={6}
                subtitle
                style={{ letterSpacing: 0, textIndent: 0 }}
              >E-Commerce Site</Heading></li>
              </Heading>
            </Box>
          </Columns.Column>

          {/* mobile dev */}
          <Columns.Column className="indiv">
            <Box textAlignment="centered">
              {/* <Container fluid id="mobile-development-icon" /> */}
              <Tile className="wf_image wf_svg" id="mobile-development-icon" />
              <div style={{ padding: "3px" }}>
                <img src={require("./../../../assets/images/line.png")} />
              </div>
              <Heading size={6} style={{ letterSpacing: 1, textIndent: 0, paddingBottom: "20px", textAlign: "center"}} id="mobile">
                Mobile Development
              </Heading>
              <Heading
                size={6}
                style={{ letterSpacing: 1, textIndent: 0, textAlign: "center"}}
                subtitle
                className="native_hybrid"
              ><strong>
                Native</strong></Heading>
                <ul className="column-services" style={{textAlign: "left"}}>
                  <li><Heading
                renderAs="p"
                size={6}
                subtitle
                style={{ letterSpacing: 0, textIndent: 0}}
              >Swift (iOS)</Heading></li>
                  <li><Heading
                renderAs="p"
                size={6}
                subtitle
                style={{ letterSpacing: 0, textIndent: 0 }}
              >Java (Android)</Heading></li>
                </ul>
                <Heading
                renderAs="div"
                size={6}
                subtitle
                style={{ letterSpacing: 0, textIndent: 0, textAlign: "center" }}
                className="native_hybrid"
              ><strong>
                Hybrid</strong></Heading>
                <ul className="column-services" style={{textAlign: "left"}}>
                  <li>
                  <Heading
                renderAs="p"
                size={6}
                subtitle
                style={{ letterSpacing: 0, textIndent: 0 }}
              >React Native</Heading>
              </li>
                </ul>
            </Box>
          </Columns.Column>

          {/* maintenance & other works */}
          <Columns.Column  className="indiv">
            <Box textAlignment="centered">
              {/* <Container fluid id="maintenance-icon" /> */}
              <Tile className="wf_image wf_svg" id="maintenance-icon" />
              <div style={{ padding: "3px" }}>
                <img src={require("./../../../assets/images/line.png")} />
              </div>
              <Heading size={6} style={{ letterSpacing: 1, textIndent: 0, paddingBottom: "20px", textAlign: "center" }} id="maintenance">
                Maintenance & Other Services
              </Heading>
              <Heading
                renderAs="ul"
                size={6}
                subtitle
                style={{ letterSpacing: 0, textIndent: 0, textAlign: "left" }}
                className="column-services"
              >
                <li><Heading
                renderAs="p"
                size={6}
                subtitle
                style={{ letterSpacing: 0, textIndent: 0 }}
              >IT Consultancy</Heading></li>
                <li><Heading
                renderAs="p"
                size={6}
                subtitle
                style={{ letterSpacing: 0, textIndent: 0 }}
              >Email Migration & Hosting</Heading></li>
                <li><Heading
                renderAs="p"
                size={6}
                subtitle
                style={{ letterSpacing: 0, textIndent: 0 }}
              >Web Hosting</Heading></li>
                <li><Heading
                renderAs="p"
                size={6}
                subtitle
                style={{ letterSpacing: 0, textIndent: 0 }}
              >Domain Registration & Managment</Heading></li>
              </Heading>
            </Box>
          </Columns.Column>
        </Columns>
      </Slide>
    );
  }
}

export default ServicesOffered;
