import React from 'react'
import styled from 'styled-components';

function WorksButton(props) {


  const Button = styled.button`
    color: #000;
  font-family: FiraSans, SourceSansPro;
  font-size: 15px;
  text-transform: uppercase;
  text-indent: 3px;

  background-color: #f5f5f5;
  border: 1px solid #000;
  padding: 10px;
  cursor: pointer;
  :hover {
    color: #000;
    font-family: FiraSans-Medium, SourceSansPro-Semibold;
    text-decoration: none;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
      0 0 8px rgba(37, 37, 37, 0.6);
    text-decoration: none;
  }
    
    `;


  return (
    <div className="columns is-mobile" style={{margin:'auto'}}>
      <div className="column is-12-mobile "> 
        <Button><a href={props.pageURL}>{props.message}</a></Button>
      </div>
      

    </div>
  )

} export default WorksButton;