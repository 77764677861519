import React from 'react'
import './WorkModal.css'

class WorkModal extends React.Component {
    constructor(props) {
        super(props)
        this.displayImages = this.displayImages.bind(this)
    }

    displayImages(source) {
        return <img src={source} className="img-fluid" width="100%" />
    }

    render() {
        let title = this.props.title
        let subtitle = this.props.subtitle
        let details = this.props.details
        let link = this.props.link
        let year = this.props.year
        let headerImage = this.props.headerImage
        let screenshots = this.props.screenshots
        let closeBtn = this.props.closeBtn

        return (
            <div id="workModal">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-4">
                            <h3 className="work-title-header">{title}</h3>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-8 d-flex align-items-center justify-content-end">
                            {closeBtn}
                        </div>
                    </div>
                </div>
                <figure id="figure-img">
                    <img src={headerImage} alt={title} className="img-fluid" />
                </figure>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-3">
                            <h2 className="work-title"> {year} </h2>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-9">
                            <h2 className="work-title">{title}</h2>
                            <h3 className="work-subtitle">{subtitle}</h3>
                            <p className="work-details text-justify">
                                {details}
                                <br />
                                <a href="#">{link}</a>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    {screenshots.map(this.displayImages)}
                </div>
            </div>
        )
    }
}

export default WorkModal