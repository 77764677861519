import React from "react";
import Slide from "../../../Components/Slide";
import Zoom from "react-reveal";
import Columns from "react-bulma-components/lib/components/columns";

import "./styles.css";

import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";

class Partners extends React.Component {
  render() {
    return (
      <Slide id="partners" number={6} title="Partners" subtitle="Whom we work with">
        {/* <div style={{ marginBottom: "6.4rem" }} /> */}

        <div className="home-section-content" id="container_partners">

          <Columns className="client-content-row row vertical-align-middle">

            {/* seed ventures */}
            <Columns.Column className="partner">
              <Box textAlignment="centered" className="box">
              <a href="https://www.seedventures.biz/">
                <Zoom>
                  <img
                    src={require("./../../../assets/images/SeedVenturesLogo.png")}
                    alt="Seed Ventures"
                    height="50px"
                    width="auto"
                    className="home-partner-img"
                  />
                </Zoom>
                <Heading size={6} style={{ letterSpacing: 1, textIndent: 0, paddingBottom: "20px"}} className="name">
                  Seed Ventures
                </Heading>
                <Heading
                  renderAs="p"
                  size={6}
                  subtitle
                  style={{ letterSpacing: 0, textIndent: 0 }}
                  className="description"
                >
                  Associate Partner
                </Heading>
                </a>
              </Box>
            </Columns.Column>

            {/* nucleo consulting */}
            <Columns.Column className="partner">
              <Box textAlignment="centered" className="box">
              <a href="https://www.nucleoconsulting.com/">
                <Zoom>
                  <img
                    src={require("./../../../assets/images/nucleoconsultingLogo.png")}
                    alt="Nucleo Consulting"
                    height="50px"
                    width="auto"
                    className="home-partner-img"
                  />
                </Zoom>
                <Heading size={6} style={{ letterSpacing: 1, textIndent: 0, paddingBottom: "20px"}} className="name">
                  Nucleo Consulting
                </Heading>
                <Heading
                  renderAs="p"
                  size={6}
                  subtitle
                  style={{ letterSpacing: 0, textIndent: 0 }}
                  className="description"
                >
                  Technology Partner
                </Heading>
                </a>
              </Box>
            </Columns.Column>

            {/* DPO */}
            <Columns.Column className="partner">
              <Box textAlignment="centered" className="box">
                <Zoom>
                  <img
                    src={require("./../../../assets/images/dpo.jpg")}
                    alt="DPO"
                    height="50px"
                    width="auto"
                    className="home-partner-img"
                  />
                </Zoom>
                <Heading size={7} style={{ letterSpacing: 1, textIndent: 0, paddingBottom: "20px"}} className="name">
                  Data Protection Officer Pte. Ltd.
                </Heading>
                <Heading
                  renderAs="p"
                  size={6}
                  subtitle
                  style={{ letterSpacing: 0, textIndent: 0 }}
                  className="description"
                >
                  Data Protection Service Partner
                </Heading>
              </Box>
            </Columns.Column>

            {/* AWS */}
            <Columns.Column className="partner">
              <Box textAlignment="centered" className="box">
              <a href="https://aws.amazon.com/">
                <Zoom>
                  <img
                    src={require("./../../../assets/images/AmazonWebServicesLogo.png")}
                    alt="AWS"
                    height="50px"
                    width="auto"
                    className="home-partner-img"
                  />
                </Zoom>
                <Heading size={6} style={{ letterSpacing: 1, textIndent: 0, paddingBottom: "20px"}} className="name">
                  Amazon Web Services
                </Heading>
                <Heading
                  renderAs="p"
                  size={6}
                  subtitle
                  style={{ letterSpacing: 0, textIndent: 0 }}
                  className="description"
                >
                  Service Partner
                </Heading>
                </a>
              </Box>
            </Columns.Column>

            {/* fortiedge */}
            <Columns.Column className="partner">
              <Box textAlignment="centered" className="box">
              <a href="https://www.fortiedge.com/">
                <Zoom>
                  <img
                    src={require("./../../../assets/images/fortiedge.png")}
                    alt="Fortiedge"
                    height="50px"
                    width="auto"
                    className="home-partner-img"
                  />
                </Zoom>
                <Heading size={6} style={{ letterSpacing: 1, textIndent: 0, paddingBottom: "20px"}} className="name">
                  Fortiedge
                </Heading>
                <Heading
                  renderAs="p"
                  size={6}
                  subtitle
                  style={{ letterSpacing: 0, textIndent: 0 }}
                  className="description"
                >
                  Security Partner
                </Heading>
                </a> 
              </Box>
            </Columns.Column>
            
          </Columns>
        </div>
      </Slide>
    );
  }
}

export default Partners;
