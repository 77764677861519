import React from "react";
import Fade from "react-reveal/Fade";
import Columns from "react-bulma-components/lib/components/columns";
import Tile from "react-bulma-components/lib/components/tile";
import './Contact.css'
class Contact extends React.Component {

  componentWillMount() {
    console.dir(this.props.location)
    window.scrollTo(0, 0);
    this.location = "home";
    console.log("current location: " + this.location);
  }

  render() {
    return (
      <section id="contact">
        <Tile vertical>
          <Fade>
            <Tile>
              <Tile size={2} />
              <Tile size={8} style={{ margin: "0", padding: "20px 10px" }}>
                <div className="ContactForm tile is-vertical">
                  <br/><br/>
                  <h2
                    style={{ borderBottom: "1px solid", padding: "0 0 15px 0" }}
                    className="section-subtitle tile"
                  >
                    Office
                  </h2>
                  <div id="map_wrapper" className="tile is-parent">
                    <div id="contact_map" className="tile is-6">
                      <iframe
                        id="contact_map"
                        src="https://tools.onemap.sg/amm/amm.html?&marker=latLng:1.4373211012375,103.842085763701!colour:red&zoomLevl=17&popupWidth=200&popupHeight=500&design=Default"
                        height="400px"
                        width="400px"
                        scrolling="no"
                        frameBorder="0"
                        allowFullScreen
                      />
                    </div>
                    <br/>
                    <div id="map_desc" className="tile is-6">
                      <h2 className="heading">WE ARE AT:</h2>
                      <p>
                        <img
                          id="icon-marker"
                          className="contact-page-icon"
                          style={{ paddingRight: "5px" }}
                          src={require("./../../assets/images/location.png")}
                          height="15px"
                        />
                        <span className="locations">
                          1 Yishun Industrial Street 1 #06-19, A'Posh Bizhub
                          Singapore 768160
                        </span>
                      </p>
                      <br />
                      <h2 className="heading">NEAREST MRT STATIONS:</h2>
                      <p className="locations">
                        NS13 Yishun MRT Station <br />
                        NS14 Khatib MRT Station <br />
                      </p>
                      <br />
                      <h2 className="heading">NEAREST BUS:</h2>
                      <p className="locations">
                        {/* <a href="https://www.google.com/maps/dir/yishun/a'posh+bizhub/@1.4333619,103.8370749,17z/am=t/data=!4m14!4m13!1m5!1m1!1s0x31da1412dd5331db:0x500f7acaedaa5d0!2m2!1d103.8353628!2d1.430368!1m5!1m1!1s0x31da1461ab36c903:0xf37b45376923ef33!2m2!1d103.842101!2d1.4370868!3e3"> */}
                          Bus 811, Yishun Temp. Interchange
                        {/* </a> */}
                        <br />
                        (3 stops)
                      </p>
                    </div>
                  </div>
                </div>
              </Tile>
              <Tile size={2} />
            </Tile>
            <Tile kind="parent" style={{ marginTop: "10px", padding: "0" }}>
              <Tile kind="child" size={3} />
              <Tile vertical style={{ margin: "0", padding: "0" }}>
                <Tile id="contact-details">
                  <Columns className="is-mobile">
                    <Columns.Column />
                    <Columns.Column className="is-narrow">
                      <Tile vertical>
                        <Tile>
                          <h2 className="section-subtitle contact-subtitle">
                            Contact
                          </h2>
                        </Tile>
                        <Tile>
                          <div id="contact-detail-icon">
                            <img
                              className="contact-page-icon"
                              style={{ paddingRight: "5px", marginLeft: 0 }}
                              src={require("./../../assets/images/email.png")}
                              height="15px"
                            />
                          </div>

                          <a href="mailto:hello@ezsofe.com" id="contact-email">
                            hello@ezsofe.com
                          </a>
                        </Tile>
                      </Tile>
                    </Columns.Column>
                    <Columns.Column />
                  </Columns>
                </Tile>
              </Tile>
              <Tile size={1} />
              <Tile style={{ margin: "0", padding: "0" }}>
                <Tile vertical id="social-details">
                  <Columns className="is-mobile">
                    <Columns.Column />
                    <Columns.Column narrow>
                      <Tile vertical>
                        <Tile>
                          <h2 className="section-subtitle contact-subtitle">
                            Social
                          </h2>
                        </Tile>
                        <Tile>
                          <a href="https://www.facebook.com/Ezsofe/">
                            <img
                              className="contact-page-icon"
                              style={{
                                marginRight: "15px",
                                marginBottom: "10px"
                              }}
                              src={require("./../../assets/images/FaceBookButton.png")}
                              alt="Facebook"
                            />
                          </a>
                          <a href="https://www.linkedin.com/company/ezsofe/?originalSubdomain=sg">
                            <img
                              className="contact-page-icon"
                              style={{
                                marginRight: "15px",
                                marginBottom: "10px"
                              }}
                              src={require("./../../assets/images/LinkedInButton.png")}
                              alt="LinkedIn"
                            />
                          </a>
                        </Tile>
                      </Tile>
                    </Columns.Column>
                    <Columns.Column />
                  </Columns>
                </Tile>
              </Tile>
              <Tile
                kind="child"
                size={3}
                style={{ margin: "0", padding: "0" }}
              />
            </Tile>
          </Fade>
        </Tile>
      </section>
    );
  }
}

export default Contact;
