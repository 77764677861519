import React from 'react'

import styled from 'styled-components';
import { string } from 'postcss-selector-parser';

export default class Usecase2 extends React.Component {

    render() {

        const Img = styled.img`
        width: 80%;
        height:300px;
        position: relative;
        overflow: hidden;
        margin-left:12%;
        border-radius: 8%;
        box-shadow: 10px 10px 5px grey;
        
        `;
        const Container = styled.div`
    padding-left:1%;
    
        
    `
        return (

            // <Container>
            <div class="columns ">
                <div class="column is-2"></div>

                <div class="column is-3 ">
                    <div>
                    <br /><br />
                        <h2 style={{fontFamily: "Times New Roman", fontWeight: 900}}>Solutions</h2>
                    </div>
                    <div style={{fontFamily: "Times New Roman"}}>
                        {this.props.challenge}
                    </div>
                    <div>
                        <p><br /></p>
                    </div>
                    <div style={{fontFamily: "Times New Roman"}}>
                        {this.props.challenge2}
                    </div>
                </div>
                <div class="column is-5 "><Img src={this.props.imageURL} />
                </div>
                <div class="column is-2"></div>


            </div>
            // </Container>


        )
    }
}
