import React from "react";
import ReactDOM from "react-dom";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import "./About.css";
import bio from "../../data/sdp";

import Tile from "react-bulma-components/lib/components/tile";
import Columns from "react-bulma-components/lib/components/columns";

import Staff from "../../Components/staff";

class About extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <br />
        <br />
        <br />
        <Tile kind="ancestor">
          <Tile />
          <Tile size={9} vertical>
            <Tile vertical>
              <Tile kind="child" size={12} />
              <Tile ref="About">
                <Tile vertical>
                  <Tile style={{ textAlign: "left", marginBottom: "20px" }}>
                    <Tile />
                    <Tile size={12}>
                      <Tile className="about-ezsofe" kind="child" size={5}>
                        <span className="content-title-light">About</span>
                        <span className="content-title"> Ezsofe </span>
                        <img
                          src={require("./../../assets/images/ezsofe-e.gif")}
                          height="auto"
                          width="30px"
                          style={{ display: "inline" }}
                        />
                      </Tile>
                    </Tile>
                    <Tile />
                  </Tile>
                  <Tile className="row" height="80%">
                    <Tile
                      kind="parent"
                      size={6}
                      style={{ alignItems: "center" }}
                    >
                      <Tile kind="child" size={12}>
                        <img
                          src={require("./../../assets/images/EzsofeFounders.jpg")}
                          width="95%"
                        />
                      </Tile>
                    </Tile>
                    <Tile className="optimizing" size={6} vertical>
                      <Zoom>
                        <div
                          className="row"
                          style={{ marginTop: "15px", marginBottom: "15px" }}
                        >
                          <div className="col-md-1" style={{ padding: "0" }} />
                          <div className="col-md-11" style={{ padding: "0" }}>
                            <h5
                              style={{
                                borderLeft: "1px solid",
                                paddingLeft: "10px",
                                textAlign: "left",
                                fontSize: "20px"
                              }}
                              className="content-title-light"
                            >
                              Optimizing your capabilities
                            </h5>
                          </div>
                        </div>
                      </Zoom>
                      <p
                        className="content-para text-justify"
                        style={{ fontSize: "20px" }}
                      >
                        Ezsofe was founded in 2014 by three Nanyang Polytechnic,
                        School of Information Technology (NYP SIT) alumni.
                        Ezsofe is a systems integration company that aims to
                        provide affordable IT solutions that helps SMEs go
                        digital and streamline their business processes.
                      </p>
                      <Fade>
                        <div className="row client-content-btn d-flex justify-content-center">
                          <Link to="/Contact" className="button-link">
                            Contact Us
                          </Link>
                        </div>
                      </Fade>
                    </Tile>
                    <Tile />
                  </Tile>
                </Tile>
              </Tile>
              <Tile kind="child" size={12} className="about-small-spacing" />
              <Tile ref="Team">
                <Tile
                  className="container-fluid"
                  className="tile-team"
                  vertical
                >
                  <Tile className="the-team">
                    <Tile
                      size={12}
                      style={{
                        textAlign: "left",
                        borderBottom: "1px solid",
                        marginBottom: "20px"
                      }}
                    >
                      <span className="content-title-light">The</span>
                      <span className="content-title"> Team </span>
                    </Tile>
                  </Tile>
                  <Columns className="staff">
                    {bio.map(profile => {
                      if (profile.type == "fulltime") {
                        return (
                          <Columns.Column
                            key={profile.key}
                            // size={4}
                            className="about-team-top"
                          >
                            <Fade bottom>
                              <Staff staff={profile} />
                            </Fade>
                          </Columns.Column>
                        );
                      }
                    })}
                  </Columns>
                </Tile>
              </Tile>
              <Tile kind="child" size={12} className="about-small-spacing" />
              <Tile ref="StudentDevelopers">
                <Tile className="row-student-devs" vertical>
                  <Tile
                    style={{
                      textAlign: "left",
                      borderBottom: "1px solid",
                      marginBottom: "20px"
                    }}
                  >
                    <span className="content-title-light">Student</span>
                    <span className="content-title"> Developers </span>
                  </Tile>
                  <Tile>
                    <h5
                      style={{ textAlign: "left", fontSize: "18px" }}
                      className="content-title-light"
                      id="student_devs_desc"
                    >
                      Ezsofe collaborated with polytechnics and universities to
                      introduce Student Development Programme where Ezsofe
                      mentors selected students and provide them exposure to the
                      IT industry as early as year one.
                    </h5>
                  </Tile>
                  <br />
                  <Tile className="row" style={{ margin: "0, 10px, 0, 10px" }}>
                    <Columns size={12} is-mobile>
                      {bio.map(profile => {
                        if (profile.type != "fulltime") {
                          return (
                            <Columns.Column
                              key={profile.key}
                              size={4}
                              className="student-devs"
                            >
                              <div className="d-flex justify-content-center">
                                <Fade bottom>
                                  <Staff staff={profile} />
                                </Fade>
                              </div>
                            </Columns.Column>
                          );
                        }
                      })}

                      {/* </OwlCarousel> */}
                    </Columns>
                  </Tile>
                </Tile>
              </Tile>
            </Tile>
            <Tile className="about-space" size={12} />
          </Tile>
          <Tile />
        </Tile>
      </div>
    );
  }
}

export default About;
