import React from 'react'
// import './usecase1.css'
import styled from 'styled-components';
import { string } from 'postcss-selector-parser';
import './features.css'
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';

export default class Features extends React.Component {

    render() {
        // const Container = styled.div`
        // background-image: url(${this.props.image});

        // background-position: center; 
        // border: none;
        // padding: 16px ;
        // text-align: center;
        // font-size: 16px;
        // margin: 4px 2px;
        // transition: 0.3s;
        // height:400px;
        // display: flex;
        // align-items: center;
        // border-radius:5%;
        // `

        // const Header = styled.h1`
        // position: relative;
        // `



        return (
            // <div>
            //     <Header className="featureHeader">{this.props.header}</Header>
            //     <br />
            //     <p className="para1">{this.props.content}</p>
            //     <Container className="columns is-desktop is-12 containerusecase " onMouseEnter>
            //     </Container>
            // </div>
            <div>
                <h3> <i class="fas fa-check-square"></i> {this.props.header1}</h3>
                <p className="para2">{this.props.feature1}</p>
                <br />
                <h3> <i class="fas fa-check-square"></i> {this.props.header2}</h3>
                <p className="para2">{this.props.feature2}</p>
                <br />
                <h3> <i class="fas fa-check-square"></i> {this.props.header3}</h3>
                <p className="para2">{this.props.feature2}</p>

            </div >


        )
    }
}
