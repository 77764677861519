import React from "react";
import PropTypes from "prop-types";
import SlideAnimation from "react-reveal/Slide";
import Fade from "react-reveal/Fade";

import Hero from "react-bulma-components/lib/components/hero";
import Heading from "react-bulma-components/lib/components/heading";
import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";

import "./styles.css";

export default class Slide extends React.Component {
  // static propTypes = {
  //   id: PropTypes.string.isRequired,
  //   number: PropTypes.number.isRequired,
  //   title: PropTypes.string.isRequired,
  //   subtitle: PropTypes.string.isRequired
  // };

  render() {
    // let { id, number, title, subtitle, children } = this.props;

    return (
      <Hero
        id={this.props.id}
        className="slide"
        size="fullheight"
        style={{ paddingTop: 31 }}
      >
        <Heading>
          <Columns gapless>
            <Columns.Column narrow>
              <SlideAnimation right cascade>
                <Heading size={2}>{this.props.number}</Heading>
              </SlideAnimation>
            </Columns.Column>
            <Columns.Column>
              <SlideAnimation right>
                <Heading size={4} subtitle>
                  {this.props.title}
                </Heading>
              </SlideAnimation>
              <SlideAnimation delay={100} right>
                <Heading size={6} subtitle>
                  {this.props.subtitle}
                </Heading>
              </SlideAnimation>
            </Columns.Column>
          </Columns>
        </Heading>
        <Hero.Body>
          <Fade>
            <Columns>
              <Columns.Column>
                {this.props.children}</Columns.Column>
            </Columns>
          </Fade>
        </Hero.Body>
      </Hero>

      // <section className="hero slide is-fullheight" style={{ paddingTop: 31 }}>

      //   < div className="title" >
      //     <div className="columns is-gapless">
      //       <div className="column is-narrow  " >
      //         <SlideAnimation left>
      //           {/* {this.props.id} */}
      //           <p className="title is-1" >{this.props.number}</p>

      //         </SlideAnimation>

      //       </div>
      //       <div className="column  is-narrow" >
      //         <SlideAnimation right >
      //           <h1 className="title is-5"> {this.props.title}</h1>

      //         </SlideAnimation>
      //         <SlideAnimation delay={100} right>
      //           <h2 className="subtitle is-6">  {this.props.subtitle}</h2>
      //         </SlideAnimation>


      //       </div>
      //     </div>

      //   </div>
      //   <div className="hero-body">
      //     <Fade right>
      //       <div className="container is-fluid">
      //         {this.props.children}
      //       </div>
      //     </Fade>
      //   </div>
      // </section >





    );
  }
}
