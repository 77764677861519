import React from 'react'
import ReactDOM from 'react-dom';
import Image from '../Works/Image/Image'
import WorkModal from '../Works/WorkModal/WorkModal.js'
import Usecase1 from '../../Components/workusecase/usecase1'
import Usecase2 from '../../Components/workusecase/usecase2'
import ShowCaseFeatures from '../../Components/showcasefeature/showcasefeature'
import Features from '../../Components/usecasefeature/feature'


import sc1 from '../../assets/images/work-showcase.jpg'
import sc2 from '../../assets/images/work-showcase2.jpg'
import sc3 from '../../assets/images/work-showcase3.jpg'
import sc4 from '../../assets/images/KIM-website.PNG'
import kimhomemac from '../../assets/images/kimhomemac.PNG'
import kimMobile from '../../assets/images/kimhp.png'

import Slide from 'react-reveal/Slide'
import Fade from 'react-reveal/Fade'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import Scrollspy from 'react-scrollspy'
import Section from 'react-bulma-components/lib/components/section';
import Container from 'react-bulma-components/lib/components/container';
import Heading from 'react-bulma-components/lib/components/heading';
import Button from 'react-bulma-components/lib/components/button';

import Zoom from "react-reveal/Zoom";
import './usecase.css'

const prefix_url = "http://localhost:3000";
class BakersWellUseCase extends React.Component {

    componentWillMount() {
        console.dir(this.props.location)
        window.scrollTo(0, 0);
        this.location = "home";
        console.log("current location: " + this.location);
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        // Add event listeners for scrolling
        window.addEventListener('wheel', this.onWheelScroll, false);
        window.addEventListener('keydown', this.onKeyDownScroll, false);
    }

    // Scrolling
    componentWillUnmount() {
        // Removes customer listener and re-enable default scrolling
        window.removeEventListener('wheel', this.onWheelScroll, false);
        window.addEventListener('keydown', this.onKeyDownScroll, false);
    }

    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
            selectedWork: {},
            CES: [
                {
                    id: 0,
                    title: 'Title of Work',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc1,
                    screenshots: [sc1, sc2, sc3]
                },
                {
                    id: 1,
                    title: 'Title of Work',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc1,
                    screenshots: [sc1, sc2, sc3]
                }
            ],
            DEH: [
                {
                    id: 0,
                    title: 'Title of Work',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc1,
                    screenshots: [sc1, sc2, sc3]
                },
                {
                    id: 1,
                    title: 'Title of Work',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc1,
                    screenshots: [sc1, sc2, sc3]
                }
            ],
            Maintain: [
                {
                    id: 0,
                    title: 'Title of Work',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc3,
                    screenshots: [sc1, sc2, sc3]
                },
                {
                    id: 1,
                    title: 'Title of Work',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc3,
                    screenshots: [sc1, sc2, sc3]
                }
            ],
            sdp: [
                {
                    id: 0,
                    title: 'Title of Work',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc1,
                    screenshots: [sc1, sc2, sc3]
                },
                {
                    id: 1,
                    title: 'SDP id 1',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc3,
                    screenshots: [sc1, sc2, sc3]
                }
            ],
            GalleryHeaders: [
                {
                    name: 'CMS Logistics'
                },
                {
                    name: 'KIM'
                }
            ]
        }

        this.show = this.show.bind(this)
        this.close = this.close.bind(this)
        this.eachImage = this.eachImage.bind(this)
        this.renderModal = this.renderModal.bind(this)
        this.check = this.check.bind(this)

        /** Location */
        this.location = "home";
        console.log("Location is now " + this.location);
        // this.onWheelScroll = this.onWheelScroll.bind(this);

        /** Refs */
        this.rf_home = React.createRef();
        this.rf_solutions = React.createRef();
        this.rf_development = React.createRef();
        this.rf_maintenance = React.createRef();
    }

    check() {
        if (this.props.location.hash != null) {
            if (this.props.location.hash == "#pills-CES") {
                this.status = true;
                this.ces = " active show";
            }
            else if (this.props.location.hash == "#pills-DEH") {
                this.status = true;
                this.deh = " active show";
            }
            else if (this.props.location.hash == "#pills-Maintain") {
                this.status = true;
                this.M = " active show";
            }
            else if (this.props.location.hash == "#pills-all") {
                this.status = true;
                this.all = " active show";
            }
            else {
                this.status = false
                this.active = "";
            }
        }

    }
    arrowClick() {

    }

    show(obj) {
        this.setState({
            showModal: true,
            selectedWork: obj
        })
    }

    close() {
        this.setState({
            showModal: false,
            selectedWork: {}
        })
    }

    renderModal() {
        let obj = this.state.selectedWork
        let closeBtn = <button className="closeBtn" onClick={this.close}>Close</button>
        return (
            <WorkModal title={obj.title}
                subtitle={obj.subtitle}
                details={obj.details}
                link={obj.link}
                year={obj.year}
                headerImage={obj.headerImage}
                screenshots={obj.screenshots}
                closeBtn={closeBtn} />
        )
    }

    eachImage(obj, id) {
        return (
            <Image key={id} imgSrc={obj.headerImage} alt={obj.id}
                clickFn={() => this.show(obj)} />
        )
    }



    render() {
        const images1 = [
            {
                original: `${prefix_url}${kimhomemac}`,
                thumbnail: `${prefix_url}${kimhomemac}`





            },
            {
                original: `${prefix_url}${kimMobile}`,
                thumbnail: `${prefix_url}${kimMobile}`





            },

        ]
        const scrollWithOffset = (el, offset) => {
            const elementPosition = el.offsetTop - offset;
            window.scroll({
                top: elementPosition,
                left: 0,
                behavior: "smooth"
            })
        }

        return (
            <div id="works-wrapper1">
                <div class="container">
                    <br/><br/>
                    <h1 className="usecaseheader">BAKER'S WELL</h1>
                    <hr />
                </div>

                <div class="tile is-ancestor">

                    {/* space */}
                    <div class="tile is-1"/>

                    {/* image */}
                    <div class="tile is-child is-6" id="picture">
                        <img
                        src={require("./../../assets/images/bakerswell.jpg")}
                        width="95%"
                      />
                    </div>

                    {/* challenges */}
                    <div class="tile is-child">
                        <Zoom>
                            <div
                                className="row"
                                style={{ marginTop: "15px", marginBottom: "15px" }}
                            >
                                <div className="col-md-1" style={{ padding: "0" }} />
                                <div className="col-md-11" style={{ padding: "0" }}>
                                <h5
                                    style={{
                                    borderLeft: "1px solid",
                                    paddingLeft: "10px",
                                    textAlign: "left",
                                    fontSize: "25px"
                                    }}
                                    className="content-title-light"
                                >
                                    CHALLENGES
                                </h5>
                                </div>
                            </div>
                        </Zoom>
                        <p style={{fontSize: "20px"}} className="content-para text-justify">
                            Baker's Well intitially adopted the POS system which lacked a pre-order functionality. This made it challenging to track customers' preorders and inventory management had been a chore. Manually tracking customer's orders and conducting stocktakes was extremely time- and effort-consuming.
                        </p>
                    </div>

                    {/* space */}
                    <div class="tile is-1"/>
                </div>

                <br/><br/><br/>

                <div class="tile is-ancestor">
                    {/* space */}
                    <div class="tile is-1"/>

                    {/* handshake */}
                    <div class="tile is-child is-6" id="picture">
                        <img
                        src={require("./../../assets/images/EzsofeBanner1.jpg")}
                        width="95%"
                      />
                    </div>

                    {/* solutions */}
                    <div class="tile is-child">
                        <Zoom>
                            <div
                                className="row"
                                style={{ marginTop: "15px", marginBottom: "15px" }}
                            >
                                <div className="col-md-1" style={{ padding: "0" }} />
                                <div className="col-md-11" style={{ padding: "0" }}>
                                <h5
                                    style={{
                                    borderLeft: "1px solid",
                                    paddingLeft: "10px",
                                    textAlign: "left",
                                    fontSize: "25px"
                                    }}
                                    className="content-title-light"
                                >
                                    SOLUTIONS
                                </h5>
                                </div>
                            </div>
                        </Zoom>
                        <p style={{fontSize: "20px"}} className="content-para text-justify">
                            The team quickly found the solution to this challenge - a custom-made tablet application that allows Baker's Well to conveniently receive and preview customers' preorder records on the go while also managing stock inventories on a centralized platform to accelerate business processes.
                        </p>
                    </div>

                    {/* space */}
                    <div class="tile is-1"/>

                </div>

                <br/><br/><br/>

                <div class="tile is-ancestor">
                    {/* space */}
                    <div class="tile is-1"/>

                    {/* ipads */}
                    <div class="is-parent is-7" id="iphonemac" vertical>

                        <div class="tile is-child" id="ipad1">
                            <img
                            src={require("./../../assets/images/bw-ipad1.png")}
                            width="95%"
                        />
                        </div>

                        <div class="tile is-child" id="ipad2">
                            <img
                            src={require("./../../assets/images/bw-ipad2.png")}
                            width="95%"
                        />
                        </div>
                        
                    </div>

                    {/* space */}
                    <div class="tile is-1"/>

                    {/* FEATURES */}
                    <div class="tile is-child is-5">
                        <Zoom>
                            <div
                                className="row"
                                style={{ marginTop: "15px", marginBottom: "15px" }}
                            >
                                
                                <div className="col-md-11" style={{ padding: "0" }}>
                                    <h5
                                        style={{
                                        borderLeft: "1px solid",
                                        paddingLeft: "10px",
                                        // textAlign: "left",
                                        fontSize: "25px"
                                        }}
                                        className="content-title-light"
                                    >FEATURES</h5>
                                    <div className="col-md-1" style={{ padding: "0" }} />
                                </div>
                            </div>
                        </Zoom>
                        <br/>
                        <p style={{fontSize: "20px", textAlign: "left"}} className="content-para text-justify">
                            <strong>Comprehensive POS system</strong><br/>
                            Easily view and keep track of customers' preorders, easily editing and updating POS records even for the less tech-savvy.
                            <br/><br/><br/>
                            <strong>Integrated Tablet Application</strong><br/>
                            Tablet Application in addition to web client for the bakery to review their stock inventory on the go.
                            <br/><br/><br/>
                            <strong>Conveniently keep track of stock inventory</strong><br/>
                            Features such as Stocktake and Transfer Stock allows shopkeepers to conducively manage stock inventories.
                        </p>
                    </div>

                    {/* space */}
                    <div class="tile is-1"/>

                </div>

                
            </div >
        )
    }


}


export default BakersWellUseCase