import React from 'react'
import ReactDOM from 'react-dom';
import Image from '../Works/Image/Image'
import WorkModal from '../Works/WorkModal/WorkModal.js'
import Usecase1 from '../../Components/workusecase/usecase1'
import Usecase2 from '../../Components/workusecase/usecase2'
import ShowCaseFeatures from '../../Components/showcasefeature/showcasefeature'
import Features from '../../Components/usecasefeature/feature'


import sc1 from '../../assets/images/work-showcase.jpg'
import sc2 from '../../assets/images/work-showcase2.jpg'
import sc3 from '../../assets/images/work-showcase3.jpg'
import sc4 from '../../assets/images/KIM-website.PNG'
import kimhomemac from '../../assets/images/kimhomemac.PNG'
import kim from '../../assets/images/kimhome.png'
import kimMobile from '../../assets/images/kimhp.png'
import handshsake from '../../assets/images/handshake.jpg'


import Slide from 'react-reveal/Slide'
import Fade from 'react-reveal/Fade'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import Scrollspy from 'react-scrollspy'
import Section from 'react-bulma-components/lib/components/section';
import Container from 'react-bulma-components/lib/components/container';
import Heading from 'react-bulma-components/lib/components/heading';
import Button from 'react-bulma-components/lib/components/button';

import Zoom from "react-reveal/Zoom";

import './usecase.css'


// import ImageGallery from 'react-image-gallery';
// import ScrollspyNav from 'react-scrollspy-nav'

// import { Breadcrumb, BreadcrumbItem } from 'reactstrap'

const prefix_url = "http://localhost:3000";
class KimUseCase extends React.Component {

    componentWillMount() {
        console.dir(this.props.location)
        window.scrollTo(0, 0);
        this.location = "home";
        console.log("current location: " + this.location);
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        // Add event listeners for scrolling
        window.addEventListener('wheel', this.onWheelScroll, false);
        window.addEventListener('keydown', this.onKeyDownScroll, false);
    }

    // Scrolling
    componentWillUnmount() {
        // Removes customer listener and re-enable default scrolling
        window.removeEventListener('wheel', this.onWheelScroll, false);
        window.addEventListener('keydown', this.onKeyDownScroll, false);
    }

    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
            selectedWork: {},
            CES: [
                {
                    id: 0,
                    title: 'Title of Work',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc1,
                    screenshots: [sc1, sc2, sc3]
                },
                {
                    id: 1,
                    title: 'Title of Work',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc1,
                    screenshots: [sc1, sc2, sc3]
                }
            ],
            DEH: [
                {
                    id: 0,
                    title: 'Title of Work',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc1,
                    screenshots: [sc1, sc2, sc3]
                },
                {
                    id: 1,
                    title: 'Title of Work',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc1,
                    screenshots: [sc1, sc2, sc3]
                }
            ],
            Maintain: [
                {
                    id: 0,
                    title: 'Title of Work',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc3,
                    screenshots: [sc1, sc2, sc3]
                },
                {
                    id: 1,
                    title: 'Title of Work',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc3,
                    screenshots: [sc1, sc2, sc3]
                }
            ],
            sdp: [
                {
                    id: 0,
                    title: 'Title of Work',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc1,
                    screenshots: [sc1, sc2, sc3]
                },
                {
                    id: 1,
                    title: 'SDP id 1',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc3,
                    screenshots: [sc1, sc2, sc3]
                }
            ],
            GalleryHeaders: [
                {
                    name: 'CMS Logistics'
                },
                {
                    name: 'KIM'
                }
            ]
        }

        this.show = this.show.bind(this)
        this.close = this.close.bind(this)
        this.eachImage = this.eachImage.bind(this)
        this.renderModal = this.renderModal.bind(this)
        this.check = this.check.bind(this)

        /** Location */
        this.location = "home";
        console.log("Location is now " + this.location);
        // this.onWheelScroll = this.onWheelScroll.bind(this);

        /** Refs */
        this.rf_home = React.createRef();
        this.rf_solutions = React.createRef();
        this.rf_development = React.createRef();
        this.rf_maintenance = React.createRef();
    }

    check() {
        if (this.props.location.hash != null) {
            if (this.props.location.hash == "#pills-CES") {
                this.status = true;
                this.ces = " active show";
            }
            else if (this.props.location.hash == "#pills-DEH") {
                this.status = true;
                this.deh = " active show";
            }
            else if (this.props.location.hash == "#pills-Maintain") {
                this.status = true;
                this.M = " active show";
            }
            else if (this.props.location.hash == "#pills-all") {
                this.status = true;
                this.all = " active show";
            }
            else {
                this.status = false
                this.active = "";
            }
        }

    }
    arrowClick() {

    }

    show(obj) {
        this.setState({
            showModal: true,
            selectedWork: obj
        })
    }

    close() {
        this.setState({
            showModal: false,
            selectedWork: {}
        })
    }

    renderModal() {
        let obj = this.state.selectedWork
        let closeBtn = <button className="closeBtn" onClick={this.close}>Close</button>
        return (
            <WorkModal title={obj.title}
                subtitle={obj.subtitle}
                details={obj.details}
                link={obj.link}
                year={obj.year}
                headerImage={obj.headerImage}
                screenshots={obj.screenshots}
                closeBtn={closeBtn} />
        )
    }

    eachImage(obj, id) {
        return (
            <Image key={id} imgSrc={obj.headerImage} alt={obj.id}
                clickFn={() => this.show(obj)} />
        )
    }








    render() {
        const images1 = [
            {
                original: `${prefix_url}${kimhomemac}`,
                thumbnail: `${prefix_url}${kimhomemac}`





            },
            {
                original: `${prefix_url}${kimMobile}`,
                thumbnail: `${prefix_url}${kimMobile}`





            },

        ]
        const scrollWithOffset = (el, offset) => {
            const elementPosition = el.offsetTop - offset;
            window.scroll({
                top: elementPosition,
                left: 0,
                behavior: "smooth"
            })
        }

        return (
            <div id="works-wrapper1">
                <div class="container">
                    <br/><br/>
                    <h1 className="usecaseheader">KIM TRANSPORT SOLUTIONS</h1>
                    <hr />
                </div>

                <div class="tile is-ancestor" id="challenges">

                    {/* space */}
                    <div class="tile is-1"/>

                    {/* truck */}
                    <div class="tile is-child is-6" id="picture">
                        <img
                        src={require("./../../assets/images/truck.jpg")}
                        width="95%"
                      />
                    </div>

                    {/* challenges */}
                    <div class="tile is-child">
                        <Zoom>
                            <div
                                className="row"
                                style={{ marginTop: "15px", marginBottom: "15px" }}
                            >
                                <div className="col-md-1" style={{ padding: "0" }} />
                                <div className="col-md-11" style={{ padding: "0" }}>
                                <h5
                                    style={{
                                    borderLeft: "1px solid",
                                    paddingLeft: "10px",
                                    textAlign: "left",
                                    fontSize: "25px"
                                    }}
                                    className="content-title-light"
                                >
                                    CHALLENGES
                                </h5>
                                </div>
                            </div>
                        </Zoom>
                        <p style={{fontSize: "20px"}} className="content-para text-justify">
                        Kim initially was operating on WhatsApp and Excel sheets, with their office team manually updating jobs one-by-one on the Excel sheets, messaging the driver the job details, then calling the driver to confirm the details. It was a tedious and inefficient process. This caused many issues within the company. With frequent miscommunication between the office team and drivers there were many problems such as 'double jobs'. Changing Job details on the excel was difficult too as the office team were not sure which jobs were updated and which were not.
                        </p>
                    </div>

                    {/* space */}
                    <div class="tile is-1"/>
                </div>

                <br/><br/><br/>

                <div class="tile is-ancestor">
                    {/* space */}
                    <div class="tile is-1"/>

                    {/* handshake */}
                    <div class="tile is-child is-6" id="picture">
                        <img
                        src={require("./../../assets/images/handshake.jpg")}
                        width="95%"
                      />
                    </div>

                    {/* solutions */}
                    <div class="tile is-child">
                        <Zoom>
                            <div
                                className="row"
                                style={{ marginTop: "15px", marginBottom: "15px" }}
                            >
                                <div className="col-md-1" style={{ padding: "0" }} />
                                <div className="col-md-11" style={{ padding: "0" }}>
                                <h5
                                    style={{
                                    borderLeft: "1px solid",
                                    paddingLeft: "10px",
                                    textAlign: "left",
                                    fontSize: "25px"
                                    }}
                                    className="content-title-light"
                                >
                                    SOLUTIONS
                                </h5>
                                </div>
                            </div>
                        </Zoom>
                        <p style={{fontSize: "20px"}} className="content-para text-justify">
                        Our team understood Kim's problems and developed a live website to allow the office team to plan and allocate jobs more easily. At the same time, we helped the admin team by allowing them to use the website to manage HR more efficiently and effectively.
                        </p>
                    </div>

                    {/* space */}
                    <div class="tile is-1"/>

                </div>

                <br/><br/><br/>

                <div class="tile is-ancestor">
                    {/* space */}
                    <div class="tile is-1"/>

                    {/* IPHONE AND MAC*/}
                    <div class="is-parent" id="iphonemac" vertical>
                        {/* IPHONE */}
                        <div class="tile is-child is-6" id="iphone">
                            <img
                            src={require("./../../assets/images/kimhp.png")}
                            width="95%"
                            id="iphonepic"
                        />
                        </div>

                        <br/><br />

                        {/* MAC  */}
                        <div class="tile is-child is-10" id="mac">
                            <img
                            src={require("./../../assets/images/kimhomemac.PNG")}
                            width="95%"
                            id="macpic"
                        />
                        </div>
                    </div>
                    
                    <br/>

                    {/* FEATURES */}
                    <div class="tile is-child" id="iphonemacfeatures">
                        <Zoom>
                            <div
                                className="row"
                                style={{ marginTop: "15px", marginBottom: "15px" }}
                            >
                                
                                <div className="col-md-11" style={{ padding: "0" }}>
                                    <h5
                                        style={{
                                        borderLeft: "1px solid",
                                        paddingLeft: "10px",
                                        // textAlign: "left",
                                        fontSize: "25px"
                                        }}
                                        className="content-title-light"
                                    >FEATURES</h5>
                                    <div className="col-md-1" style={{ padding: "0" }} />
                                </div>
                            </div>
                        </Zoom>
                        <br/>
                        <p style={{fontSize: "20px", textAlign: "left"}} className="content-para text-justify" id="desc">
                            <strong>Real-time Application</strong><br/>
                            Changes deployed on website will notify drivers on the mobile app
                            <br/><br/><br/>
                            <strong>Integrated Mobile Application</strong><br/>
                            Mobile Application for drivers to check their jobs on the go
                            <br/><br/><br/>
                            <strong>Job Changes on Mobile Application</strong><br/>
                            Changes in jobs will be shown to mitigate misunderstanding issues for drivers (e.g. double job/cancel job)
                        </p>
                    </div>

                    {/* space */}
                    <div class="tile is-1"/>

                </div>

                
            </div >
        )
    }


}


export default KimUseCase