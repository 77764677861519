import React from "react";
import ReactDOM from "react-dom";
import Landing from "./Landing";
import About from "./About";
import Services from "./Services";
import Approach from "./Approach";
import Works from "./Works";
import Testimonials from "./Testimonials";
import Partners from "./Partners";
import Contact from "./Contact";
import Scroller from "window-scroller";
import Content from "react-bulma-components/lib/components/content";
import "./styles.css";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: window.innerWidth
    };
  }

  componentWillMount() {
    window.scrollTo(0, 0);
    this.location = "landing";
  }

  componentDidMount() {
    // window.addEventListener("wheel", this.onWheelScroll);
    // window.addEventListener("keydown", this.onKeyDownScroll);
    window.addEventListener("resize", this.onWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("wheel", this.onWheelScroll);
    window.removeEventListener("keydown", this.onKeyDownScroll);
    window.removeEventListener("resize", this.onWindowSizeChange);
  }

  onWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  pageUp = () => {
    let domNode = null;

    switch (this.location) {
      case "about":
        domNode = ReactDOM.findDOMNode(this.refs.landing);
        this.location = "landing";
        break;
      case "services":
        domNode = ReactDOM.findDOMNode(this.refs.about);
        this.location = "about";
        break;
      case "approach":
        domNode = ReactDOM.findDOMNode(this.refs.services);
        this.location = "services";
        break;
      case "works":
        domNode = ReactDOM.findDOMNode(this.refs.approach);
        this.location = "approach";
        break;
      case "testimonials":
        domNode = ReactDOM.findDOMNode(this.refs.works);
        this.location = "works";
        break;
      case "partners":
        domNode = ReactDOM.findDOMNode(this.refs.testimonials);
        this.location = "testimonials";
        break;
      case "contact":
        domNode = ReactDOM.findDOMNode(this.refs.partners);
        this.location = "partners";
        break;
      case "footer":
        domNode = ReactDOM.findDOMNode(this.refs.contact);
        this.location = "contact";
        break;
    }

    if (domNode != null) {
      this.scrolling = true;
      Scroller.to(domNode.offsetTop - 60)
        .speed(1000)
        .scroll();
      setTimeout(() => {
        this.scrolling = false;
      }, 500);
    }
  };

  pageDown = () => {
    let domNode = null;
    switch (this.location) {
      case "landing":
        domNode = ReactDOM.findDOMNode(this.refs.about);
        this.location = "about";
        break;
      case "about":
        domNode = ReactDOM.findDOMNode(this.refs.services);
        this.location = "services";
        break;
      case "services":
        domNode = ReactDOM.findDOMNode(this.refs.approach);
        this.location = "approach";
        break;
      case "approach":
        domNode = ReactDOM.findDOMNode(this.refs.works);
        this.location = "works";
        break;
      case "works":
        domNode = ReactDOM.findDOMNode(this.refs.testimonials);
        this.location = "testimonials";
        break;
      case "testimonials":
        domNode = ReactDOM.findDOMNode(this.refs.partners);
        this.location = "partners";
        break;
      case "partners":
        domNode = ReactDOM.findDOMNode(this.refs.contact);
        this.location = "contact";
        break;
      case "contact":
        domNode = "domFooter";
        this.location = "footer";
        break;
    }

    if (domNode == "domFooter") {
      console.log("hi");
      this.scrolling = true;
      Scroller.to(document.getElementById("hero_footer"))
        .speed(174)
        .scroll();
      setTimeout(() => {
        this.scrolling = false;
      }, 500);
    } else if (domNode != null) {
      this.scrolling = true;
      Scroller.to(domNode.offsetTop - 60)
        .speed(1000)
        .scroll();
      setTimeout(() => {
        this.scrolling = false;
      }, 500);
    }
  };

  onWheelScroll = e => {
    const { width } = this.state;
    const isMobile = width <= 768;

    if (isMobile) {
      return;
    }

    e.preventDefault();

    if (this.scrolling) {
      return;
    }

    if (e.deltaY < 0) {
      this.pageUp();
    } else if (e.deltaY > 0) {
      this.pageDown();
    }
  };

  onKeyDownScroll = e => {
    const { width } = this.state;
    const isMobile = width <= 768;

    if (isMobile) {
      return;
    }

    if (e.keyCode == 38) {
      e.preventDefault();

      if (this.scrolling) {
        return;
      }

      this.pageUp();
    } else if (e.keyCode == 40) {
      e.preventDefault();

      if (this.scrolling) {
        return;
      }

      this.pageDown();
    }
  };

  render() {
    return (
      <Content>
        <Landing ref="landing" />
        <About ref="about" />
        <Services ref="services" />
        <Approach ref="approach" />
        <Works ref="works" />
        <Testimonials ref="testimonials" />
        <Partners ref="partners" />
        <Contact ref="contact" />
      </Content>
    );
  }
}
export default Home;
