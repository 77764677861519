import React from 'react'
import ReactDOM from 'react-dom';
import Image from './Image/Image.js'
import WorkModal from './WorkModal/WorkModal.js'
import './Works.css'
import Worksbutton from '../../Components/worksbox/worksbutton'
import Vivus from "vivus";

import sc1 from '../../assets/images/work-showcase.jpg'
import sc2 from '../../assets/images/work-showcase2.jpg'
import sc3 from '../../assets/images/work-showcase3.jpg'
import kim from '../../assets/images/Kim.png'
import cms from '../../assets/images/cmspage.jpg'
import backupIcon from "../../assets/images/backup.svg";


import Slide from 'react-reveal/Slide'
import Fade from 'react-reveal/Fade'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import Scrollspy from 'react-scrollspy'
// import ScrollspyNav from 'react-scrollspy-nav'
// import { Breadcrumb, BreadcrumbItem } from 'reactstrap'

class Works extends React.Component {

    componentWillMount() {
        console.dir(this.props.location)
        window.scrollTo(0, 0);
        this.location = "home";
        console.log("current location: " + this.location);
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        // Add event listeners for scrolling
        window.addEventListener('wheel', this.onWheelScroll, false);
        window.addEventListener('keydown', this.onKeyDownScroll, false);

    }

    // Scrolling
    componentWillUnmount() {
        // Removes customer listener and re-enable default scrolling
        window.removeEventListener('wheel', this.onWheelScroll, false);
        window.addEventListener('keydown', this.onKeyDownScroll, false);
    }

    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
            selectedWork: {},
            CES: [
                {
                    id: 0,
                    title: 'Title of Work',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc1,
                    screenshots: [sc1, sc2, sc3]
                },
                {
                    id: 1,
                    title: 'Title of Work',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc1,
                    screenshots: [sc1, sc2, sc3]
                }
            ],
            DEH: [
                {
                    id: 0,
                    title: 'Title of Work',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc1,
                    screenshots: [sc1, sc2, sc3]
                },
                {
                    id: 1,
                    title: 'Title of Work',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc1,
                    screenshots: [sc1, sc2, sc3]
                }
            ],
            Maintain: [
                {
                    id: 0,
                    title: 'Title of Work',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc3,
                    screenshots: [sc1, sc2, sc3]
                },
                {
                    id: 1,
                    title: 'Title of Work',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc3,
                    screenshots: [sc1, sc2, sc3]
                }
            ],
            sdp: [
                {
                    id: 0,
                    title: 'Title of Work',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc1,
                    screenshots: [sc1, sc2, sc3]
                },
                {
                    id: 1,
                    title: 'SDP id 1',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc3,
                    screenshots: [sc1, sc2, sc3]
                }
            ]
        }

        this.show = this.show.bind(this)
        this.close = this.close.bind(this)
        this.eachImage = this.eachImage.bind(this)
        this.renderModal = this.renderModal.bind(this)
        this.check = this.check.bind(this)

        /** Location */
        this.location = "home";
        console.log("Location is now " + this.location);
        // this.onWheelScroll = this.onWheelScroll.bind(this);

        /** Refs */
        this.rf_home = React.createRef();
        this.rf_solutions = React.createRef();
        this.rf_development = React.createRef();
        this.rf_maintenance = React.createRef();
    }

    check() {
        if (this.props.location.hash != null) {
            if (this.props.location.hash == "#pills-CES") {
                this.status = true;
                this.ces = " active show";
            }
            else if (this.props.location.hash == "#pills-DEH") {
                this.status = true;
                this.deh = " active show";
            }
            else if (this.props.location.hash == "#pills-Maintain") {
                this.status = true;
                this.M = " active show";
            }
            else if (this.props.location.hash == "#pills-all") {
                this.status = true;
                this.all = " active show";
            }
            else {
                this.status = false
                this.active = "";
            }
        }

    }

    show(obj) {
        this.setState({
            showModal: true,
            selectedWork: obj
        })
    }

    close() {
        this.setState({
            showModal: false,
            selectedWork: {}
        })
    }

    renderModal() {
        let obj = this.state.selectedWork
        let closeBtn = <button className="closeBtn" onClick={this.close}>Close</button>
        return (
            <WorkModal title={obj.title}
                subtitle={obj.subtitle}
                details={obj.details}
                link={obj.link}
                year={obj.year}
                headerImage={obj.headerImage}
                screenshots={obj.screenshots}
                closeBtn={closeBtn} />
        )
    }

    eachImage(obj, id) {
        return (
            <Image key={id} imgSrc={obj.headerImage} alt={obj.id}
                clickFn={() => this.show(obj)} />
        )
    }

    renderWorks() {
        this.check()
        return (
            <section id="works" className="container">
                <h1 className="text-center">
                    <span className="page-title-medium">Featured </span>
                    <span className="page-title-reg">Works</span>
                </h1>

                <ul className="nav nav-pills mb-4 d-flex justify-content-center" id="pills-tab" role="tablist">
                    <li className="nav-item">
                        <a className={'nav-link' + this.all} id="pills-all-tab" data-toggle="pill" href="#pills-all" role="tab" aria-controls="pills-all" aria-selected={this.status}>
                            All
                            </a>
                    </li>
                    <li className="nav-item">
                        <a className={'nav-link' + this.ces} id="pills-CES-tab" data-toggle="pill" href="#pills-CES" role="tab" aria-controls="pills-CES" aria-selected={this.status}>
                            Customised Enterprise Solution
                            </a>
                    </li>
                    <li className="nav-item">
                        <a className={'nav-link' + this.deh} id="pills-DEH-tab" data-toggle="pill" href="#pills-DEH" role="tab" aria-controls="pills-DEH" aria-selected={this.status}>
                            Development House
                            </a>
                    </li>
                    <li className="nav-item">
                        <a className={'nav-link' + this.M} id="pills-Maintain-tab" data-toggle="pill" href="#pills-Maintain" role="tab" aria-controls="pills-Maintain" aria-selected="false">
                            Maintainance
                            </a>
                    </li>
                    <li className="nav-item">
                        <a className={'nav-link' + this.sdp} id="pills-sdp-tab" data-toggle="pill" href="#pills-sdp" role="tab" aria-controls="pills-sdp" aria-selected="false">
                            Student Development Programme
                            </a>
                    </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                    <div className={'tab-pane fade' + this.all} id="pills-all" role="tabpanel" aria-labelledby="pills-home-all">

                        <div className="content">
                            {this.state.CES.map(this.eachImage)}
                            {this.state.DEH.map(this.eachImage)}
                            {this.state.Maintain.map(this.eachImage)}
                            {this.state.sdp.map(this.eachImage)}
                        </div>
                    </div>

                    <div className={'tab-pane fade' + this.ces} id="pills-CES" role="tabpanel" aria-labelledby="pills-CES-tab">
                        <div className="content">
                            {this.state.CES.map(this.eachImage)}
                        </div>
                    </div>

                    <div className={'tab-pane fade' + this.deh} id="pills-DEH" role="tabpanel" aria-labelledby="pills-DEH-tab">
                        <div className="content">
                            {this.state.DEH.map(this.eachImage)}
                        </div>
                    </div>

                    <div className={'tab-pane fade' + this.M} id="pills-Maintain" role="tabpanel" aria-labelledby="pills-Maintain-tab">
                        <div className="content">
                            {this.state.Maintain.map(this.eachImage)}
                        </div>
                    </div>

                    <div className="tab-pane fade" id="pills-sdp" role="tabpanel" aria-labelledby="pills-sdp-tab">
                        <div className="content">
                            {this.state.sdp.map(this.eachImage)}
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    // onWheelScroll(e) {
    //     // "Re-enable" scrolling somehow
    //     if (e.deltaY < 0) { // If user scrolls up...
    //         e.preventDefault();
    //         console.log("[UP]L: " + this.location);
    //         switch(this.location) {
    //             // case "solutions":
    //             //     {
    //             //         if (ReactDOM.findDOMNode(this.refs) != null) {
    //             //             window.scrollTo(0, ReactDOM.findDOMNode(this.refs).offsetTop);
    //             //             this.location = 
    //             //         }
    //             //     }
    //             //     break;
    //             case "solutions":
    //                 {
    //                     if (ReactDOM.findDOMNode(this.rf_home.current) != null ) {
    //                         window.scrollTo(0, ReactDOM.findDOMNode(this.rf_home.current).offsetTop - 70);
    //                         this.location = "home";
    //                     }
    //                 }
    //                 break;
    //             case "development":
    //                 {
    //                     if (ReactDOM.findDOMNode(this.rf_solutions.current) != null) {
    //                         window.scrollTo(0, ReactDOM.findDOMNode(this.rf_solutions.current).offsetTop - 142);
    //                         this.location = "solutions"
    //                         console.log("-> " + this.location);
    //                     }
    //                 }
    //                 break;
    //             case "maintenance":
    //                 {
    //                     if (ReactDOM.findDOMNode(this.rf_development.current) != null) {
    //                         window.scrollTo(0, ReactDOM.findDOMNode(this.rf_development.current).offsetTop - 142);
    //                         this.location = "development";
    //                         console.log("-> " + this.location);
    //                     }
    //                 }
    //                 break;
    //             default:
    //                 {
    //                     this.location = "home";
    //                     window.scrollTo(0, 0);
    //                     console.log("-> " + this.location);
    //                 }
    //         }
    //     } else if (e.deltaY > 0) { // If user scrolls down
    //         e.preventDefault();
    //         console.log("[DOWN] L: " + this.location);
    //         switch(this.location) {
    //             case "home":
    //                 {
    //                     if (ReactDOM.findDOMNode(this.rf_solutions.current) != null) {
    //                         window.scrollTo(0, ReactDOM.findDOMNode(this.rf_solutions.current).offsetTop - 142);
    //                         this.location = "solutions";
    //                         console.log("-> " + this.location);
    //                     }
    //                 }
    //                 break;
    //             case "solutions":
    //                 {
    //                     // console.log("case3. scrolling down. state is " + this.location);
    //                     if (ReactDOM.findDOMNode(this.rf_development.current) != null) {
    //                         window.scrollTo(0, ReactDOM.findDOMNode(this.rf_development.current).offsetTop - 142);
    //                         this.location = "development";
    //                         console.log("-> " + this.location);
    //                     }
    //                 }
    //                 break;
    //             case "development":
    //                 {
    //                     if (ReactDOM.findDOMNode(this.rf_maintenance.current) != null) {
    //                         window.scrollTo(0, ReactDOM.findDOMNode(this.rf_maintenance.current).offsetTop - 142);
    //                         this.location = "maintenance";
    //                         console.log("-> " + this.location);
    //                     }
    //                 }
    //                 break;
    //             default:
    //                 {
    //                     // this.location
    //                     // console.log("case 4");
    //                 }
    //         }
    //     }
    // }

    render() {
        const scrollWithOffset = (el, offset) => {
            const elementPosition = el.offsetTop - offset;
            window.scroll({
                top: elementPosition,
                left: 0,
                behavior: "smooth"
            })
        }

        return (
            <div id="works-wrapper">
                {/* <div style={{background: "#000"}}>
                    <Breadcrumb id="breadcrumb" className="fixed-top">
                        <BreadcrumbItem><a href="#">Home</a></BreadcrumbItem>
                        <BreadcrumbItem active>Works</BreadcrumbItem>
                    </Breadcrumb>
                </div> */}

                <div id="wk_header_wrapper" ref={this.rf_home}>
                    <Fade cascade>
                        <div id="wk_header">
                        <br/><br/>
                            <h1 className="title-big"><strong>OUR WORKS</strong></h1>                            <br />
                            {/* <p>
                                Coming soon...
                            </p> */}
                        </div>
                    </Fade>
                </div>

                {/* TOP NAVIGATION BAR */}
                <nav id="category-bar" style={{height: "80px"}}>
                    <Scrollspy items={['solutions', 'integration', 'web', 'mobile', 'maintenance']} currentClassName="is-current"  offset={-300}>
                        <li className="category-button" id="nav_solutions">
                            <HashLink smooth to="#solutions" scroll={el => scrollWithOffset(el, 126)}>
                                <span style={{ display: "inline-block", maxWidth: "350px", paddingTop: "10px" }}>Customised Enterprise Solutions</span>
                            </HashLink>
                        </li>
                        <li className="category-button" id="nav_integration">
                            <HashLink smooth to="#integration" scroll={el => scrollWithOffset(el, 126)}>
                                <span style={{ display: "inline-block", maxWidth: "350px", paddingTop: "10px" }}>Systems Integration</span>
                            </HashLink>
                        </li>
                        <li className="category-button" id="nav_web">
                            <HashLink smooth to="#web" scroll={el => scrollWithOffset(el, 126)}>
                                <span style={{ display: "inline-block", maxWidth: "350px", paddingTop: "10px" }}>Web Development</span>
                            </HashLink>
                        </li>
                        <li className="category-button" id="nav_mobile">
                            <HashLink smooth to="#mobile" scroll={el => scrollWithOffset(el, 126)}>
                                <span style={{ display: "inline-block", maxWidth: "350px", paddingTop: "10px" }}>Mobile Development</span>
                            </HashLink>
                        </li>
                        <li className="category-button" id="nav_maintenance">
                            <HashLink smooth to="#maintenance" scroll={el => scrollWithOffset(el, 126)}>
                                <span style={{ display: "inline-block", maxWidth: "350px", paddingTop: "10px" }}>Maintenance & Other Services</span>
                            </HashLink>
                        </li>
                        {/* <li className="category-button one-line"><HashLink smooth to="#integration" scroll={el => scrollWithOffset(el, 126)}>Systems Integration</HashLink></li>
                        <li className="category-button one-line"><HashLink smooth to="#web" scroll={el => scrollWithOffset(el, 126)}>Web Development</HashLink></li>
                        <li className="category-button one-line"><HashLink smooth to="#mobile" scroll={el => scrollWithOffset(el, 126)}>Mobile Development</HashLink></li>
                        <li className="category-button one-line"><HashLink smooth to="#maintenance" scroll={el => scrollWithOffset(el, 126)}>Maintenance & Other Services</HashLink></li> */}
                    </Scrollspy>
                </nav>

                {/* 1. CUSTOMIZED ENTERPRISE SOLUTIONS */}
                <section id="works" ref={this.rf_solutions}>
                    <div className="container" id="solutions">
                        <div className="tile is-ancestor is-12">
                            <div style={{ paddingBottom: "10px" }} className="tile row-title is-parent">
                                <div className="row-number"></div>
                                <Slide left cascade>
                                    <span className="section-number">1</span>
                                </Slide>

                                {/* TITLE & SUBTITLE */}
                                <div className="tile is-5 row-right level">
                                    <Slide right>
                                        <div className="level-item">
                                            <h2 className="tile">
                                                <a className="anchor level-item" ></a>
                                                <div className="title-subtitle-wrapper">
                                                    <span className="tile section-title">Customised Enterprise Solutions</span>
                                                    <span className="tile is-12 section-subtitle">Enterprise Resource Planning (ERP) & Robotic Process Automation (RPA)</span>
                                                </div>

                                            </h2>
                                        </div>

                                    </Slide>
                                </div>

                            </div>
                        </div>

                        { /* FEATURES */}
                        <div className="tile is-ancestor is-12">
                            {/* work 1 */}
                            <div className="tile is-parent is-6">
                                {/* space */}
                                <div class="tile is-1"/>

                                {/* image 1 */}
                                <div className="tile is-child is-4" style={{ paddingTop: "10px" }} vertical>
                                    <Fade bottom>
                                        <img src={require("./../../assets/images/Kim.png")} className="logo" />

                                        {/* USE CASE BUTTON */}
                                        <div style={{ paddingTop: "40px" }} className="tile is-child is-10">
                                            <Fade bottom >
                                                <Worksbutton pageURL={"/kimsusecase"} message="Use case"></Worksbutton>
                                            </Fade>
                                        </div>

                                    </Fade>
                                </div>

                                {/* space */}
                                <div class="tile is-1"/>

                                {/* description 1 */}
                                {/* <div className="tile is-child is-6" style={{ paddingTop: "10px" }}>
                                    <div className="desc">
                                    <Fade bottom>
                                        <span className="tile is-12 section-subtitle"><strong>KIM Enterprise</strong></span>
                                        <span className="tile is-12 section-subtitle">Custom website for Kim</span>
                                    </Fade>
                                    </div>
                                </div> */}

                            </div>

                            {/* work 2 */}
                            {/* <div className="tile is-parent is-6"> */}
                                {/* space */}
                                {/* <div class="tile is-1"/> */}

                                {/* image 2 */}
                                {/* <div className="tile is-child is-4" style={{ paddingTop: "10px" }} vertical>
                                    <Fade bottom>
                                        <img src={require("./../../assets/images/Kim.png")} className="logo" /> */}

                                        {/* USE CASE BUTTON */}
                                        {/* <div style={{ paddingTop: "40px" }} className="tile is-child is-6">
                                            <div className="container level our-works-wrapper">
                                                <Fade bottom >
                                                    <Worksbutton2 pageURL={"/kimsusecase"}></Worksbutton2>
                                                </Fade>
                                            </div>
                                        </div>

                                    </Fade>
                                </div> */}

                                {/* space */}
                                {/* <div class="tile is-1"/> */}

                                {/* description 2 */}
                                {/* <div className="tile is-child is-6" style={{ paddingTop: "10px" }}>
                                    <Fade bottom>
                                        <span className="tile is-12 section-subtitle"><strong>KIM Enterprise</strong></span>
                                        <span className="tile is-12 section-subtitle">Custom website for Kim</span>
                                    </Fade>
                                </div>

                            </div> */}

                        </div>
                    </div>
                </section>

                <br /><br /><br/>

                {/* 2. SYSTEMS INTEGRATION */}
                <section id="works" ref={this.rf_development}>
                    <div className="container" id="integration">
                        <div className="tile is-ancestor is-12">
                            <div style={{ paddingBottom: "20px" }} className="tile row-title is-parent">
                                <div className="row-number"></div>
                                <Slide left cascade>
                                    <span className="section-number">2</span>
                                </Slide>
                                <div className="tile is-5 row-right level">
                                    <Slide right>
                                        <div className="level-item">
                                            <h2 className="tile">
                                                <a className="anchor level-item" ></a>
                                                <div className="title-subtitle-wrapper">
                                                    <span className="tile is-12 section-title">Systems integration</span>
                                                    <span className="tile is-12 section-subtitle">Seamless assimilation & transition</span>
                                                </div>

                                            </h2>
                                        </div>

                                    </Slide>
                                </div>
                            </div>
                        </div>

                        { /* FEATURES */}
                        <div className="tile is-ancestor is-12">
                            {/* work 1 */}
                            <div className="tile is-parent is-6">
                                {/* space */}
                                <div class="tile is-1"/>

                                {/* image 1 */}
                                <div className="tile is-child is-4" style={{ paddingTop: "10px" }} vertical>
                                    <Fade bottom>
                                        <img src={require("./../../assets/images/bakerswell.png")} className="logo" />

                                        {/* USE CASE BUTTON */}
                                        <div style={{ paddingTop: "40px" }} className="tile is-child is-10">
                                            <Fade bottom >
                                                <Worksbutton pageURL={"/bakerswellusecase"} message="Use case"></Worksbutton>
                                            </Fade>
                                        </div>

                                    </Fade>
                                </div>

                                {/* space */}
                                <div class="tile is-1"/>

                                {/* description 1 */}
                                {/* <div className="tile is-child is-6" style={{ paddingTop: "10px" }}>
                                <div className="desc">
                                    <Fade bottom>
                                        <span className="tile is-12 section-subtitle"><strong>Baker's Well</strong></span>
                                        <span className="tile is-12 section-subtitle">Systems Integration</span>
                                    </Fade>
                                    </div>
                                </div> */}

                            </div>

                            {/* work 2 */}
                            {/* <div className="tile is-parent is-6"> */}
                                {/* space */}
                                {/* <div class="tile is-1"/> */}

                                {/* image 2 */}
                                {/* <div className="tile is-child is-4" style={{ paddingTop: "10px" }} vertical>
                                    <Fade bottom>
                                        <img src={require("./../../assets/images/Kim.png")} className="logo" /> */}

                                        {/* USE CASE BUTTON */}
                                        {/* <div style={{ paddingTop: "40px" }} className="tile is-child is-6">
                                            <div className="container level our-works-wrapper">
                                                <Fade bottom >
                                                    <Worksbutton2 pageURL={"/kimsusecase"}></Worksbutton2>
                                                </Fade>
                                            </div>
                                        </div>

                                    </Fade>
                                </div> */}

                                {/* space */}
                                {/* <div class="tile is-1"/> */}

                                {/* description 2 */}
                                {/* <div className="tile is-child is-6" style={{ paddingTop: "10px" }}>
                                    <Fade bottom>
                                        <span className="tile is-12 section-subtitle"><strong>KIM Enterprise</strong></span>
                                        <span className="tile is-12 section-subtitle">Custom website for Kim</span>
                                    </Fade>
                                </div>

                            </div> */}

                        </div>

                    </div>
                </section>

                <br /><br /><br/>

                {/* 3. WEB DEV */}
                <section id="works" ref={this.rf_maintenance}>
                    <div className="container" id="web">
                        <div className="tile is-ancestor is-12">
                            <div style={{ paddingBottom: "20px" }} className="tile row-title is-parent">
                                <div className="row-number"></div>
                                <Slide left cascade>
                                    <span className="section-number">3</span>
                                </Slide>
                                <div className="tile is-5 row-right level">
                                    <Slide right>
                                        <div className="level-item">
                                            <h2 className="tile">
                                                <a className="anchor level-item" ></a>
                                                <div className="title-subtitle-wrapper">
                                                    <span className="tile is-12 section-title">Web development</span>

                                                    <span className="tile is-12 section-subtitle">Company site, website revamp & e-commerce site</span>
                                                </div>

                                            </h2>
                                        </div>

                                    </Slide>
                                </div>
                            </div>
                        </div>
                        
                        { /* FEATURES */}
                        <div className="tile is-ancestor is-12">
                            {/* work 1 */}
                            <div className="tile is-parent is-6">
                                {/* space */}
                                <div class="tile is-1"/>

                                {/* image 1 */}
                                <div className="tile is-child is-4" style={{ paddingTop: "10px" }} vertical>
                                    <Fade bottom>
                                        <img src={require("./../../assets/images/cobl.jpeg")} className="logo" />

                                        {/* USE CASE BUTTON */}
                                        <div style={{ paddingTop: "40px" }} className="tile is-child is-10">
                                            <Fade bottom >
                                                <Worksbutton pageURL={"http://cobl.com.sg/"} message="Click here to go to website"></Worksbutton>
                                            </Fade>
                                        </div>

                                    </Fade>
                                </div>

                                {/* space */}
                                <div class="tile is-1"/>

                                {/* description 1 */}
                                {/* <div className="tile is-child is-6" style={{ paddingTop: "10px" }}>
                                <div className="desc">
                                    <Fade bottom>
                                        <span className="tile is-12 section-subtitle"><strong>COBL</strong></span>
                                        <span className="tile is-12 section-subtitle">Custom website for COBL</span>
                                    </Fade>
                                    </div>
                                </div> */}

                            </div>

                            {/* work 2 */}
                            <div className="tile is-parent is-6">
                                {/* space */}
                                <div class="tile is-1"/>

                                {/* image 2 */}
                                <div className="tile is-child is-4" style={{ paddingTop: "10px" }} vertical>
                                    <Fade bottom>
                                        <img src={require("./../../assets/images/cms.jpg")} className="logo" />

                                        {/* USE CASE BUTTON */}
                                        <div style={{ paddingTop: "40px" }} className="tile is-child is-10">
                                            <Fade bottom >
                                                <Worksbutton pageURL={"https://cmslogistics.com/"} message="Click here to go to website"></Worksbutton>
                                            </Fade>
                                        </div>

                                    </Fade>
                                </div>

                                {/* space */}
                                <div class="tile is-1"/>

                                {/* description 2 */}
                                {/* <div className="tile is-child is-6" style={{ paddingTop: "10px" }}>
                                <div className="desc">
                                    <Fade bottom>
                                        <span className="tile is-12 section-subtitle"><strong>CMS Logistics</strong></span>
                                        <span className="tile is-12 section-subtitle">Custom website for CMS</span>
                                    </Fade>
                                    </div>
                                </div> */}

                            </div>

                        </div>

                    </div>
                </section>

                <br /><br /><br/>

                {/* 4. MOBILE DEV */}
                <section id="works" ref={this.rf_maintenance}>
                    <div className="container" id="mobile">
                        <div className="tile is-ancestor is-12">
                            <div style={{ paddingBottom: "20px" }} className="tile row-title is-parent">
                                <div className="row-number"></div>
                                <Slide left cascade>
                                    <span className="section-number">4</span>
                                </Slide>
                                <div className="tile is-5 row-right level">
                                    <Slide right>
                                        <div className="level-item">
                                            <h2 className="tile">
                                                <a className="anchor level-item" ></a>
                                                <div className="title-subtitle-wrapper">
                                                    <span className="tile is-12 section-title">Mobile development</span>
                                                    <span className="tile is-12 section-subtitle">Hybrid (React native), Native (ios) & native (android)</span>
                                                </div>

                                            </h2>
                                        </div>

                                    </Slide>
                                </div>
                            </div>
                        </div>
                        
                        { /* FEATURES */}
                        <div className="tile is-ancestor is-12">
                            {/* work 1 */}
                            <div className="tile is-vertical is-12">
                                {/* space */}
                                <div class="tile is-1"/>

                                {/* image 1 */}
                                <div className="tile is-4" style={{ paddingTop: "10px" }}>
                                    <Fade bottom>
                                        <img src={require("./../../assets/images/Kim.png")} className="logo" />
                                    </Fade>
                                </div>

                                {/* space */}
                                {/* <div class="tile is-1"/> */}

                                {/* description 1 */}
                                <div className="tile is-parent" style={{ paddingTop: "10px" }}>

                                    {/* space */}
                                    <div class="tile is-1"/>

                                    <div style={{ paddingTop: "40px" }} className="tile is-child">
                                        <Fade bottom >
                                            <span className="section-subtitle">
                                                Kindly refer to Kim's use case above.
                                            </span>
                                        </Fade>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </section>

                <br /><br /><br/>

                {/* 5. MAINTENANCE & OTHERS */}
                <section id="works" ref={this.rf_maintenance}>
                    <div className="container" id="maintenance">
                        <div className="tile is-ancestor is-12">
                            <div style={{ paddingBottom: "20px" }} className="tile row-title is-parent">
                                <div className="row-number"></div>
                                <Slide left cascade>
                                    <span className="section-number">5</span>
                                </Slide>
                                <div className="tile is-5 row-right level">
                                    <Slide right>
                                        <div className="level-item">
                                            <h2 className="tile">
                                                <a className="anchor level-item" ></a>
                                                <div className="title-subtitle-wrapper">
                                                    <span className="tile is-12 section-title">Maintenance & other services</span>

                                                    <span className="tile is-12 section-subtitle">IT consultancy, email & web hosting</span>
                                                </div>

                                            </h2>
                                        </div>

                                    </Slide>
                                </div>
                            </div>
                        </div>
                        
                        { /* FEATURES */}
                        <div className="tile is-ancestor is-12">
                            {/* work 1 */}
                            <div className="tile is-parent is-12">
                                {/* space */}
                                <div class="tile is-1"/>
                                <span className="section-subtitle">Feel free to reach out to us at</span>
                                <a href="mailto:hello@ezsofe.com" className="section-subtitle">hello@ezsofe.com</a>
                                <span className="section-subtitle">to find out more.</span>

                                {/* image 1 */}
                                {/* <div className="tile is-child is-4" style={{ paddingTop: "10px" }} vertical>
                                    <Fade bottom>
                                        <img src={require("./../../assets/images/Kim.png")} className="logo" />

                                    </Fade>
                                </div> */}

                                {/* space */}
                                {/* <div class="tile is-1"/> */}

                                {/* description 1 */}
                                {/* <div className="tile is-child is-6" style={{ paddingTop: "10px" }}>
                                <div className="desc">
                                    <Fade bottom>
                                        <span className="tile is-12 section-subtitle"><strong>KIM Enterprise</strong></span>
                                        <span className="tile is-12 section-subtitle">Custom website for Kim</span>
                                    </Fade>
                                    </div>
                                </div> */}

                            </div>

                        </div>

                    </div>
                </section>

            </div>
        )
    }
}

export default Works