import React from 'react'
import ReactDOM from 'react-dom';
import Image from '../Works/Image/Image'
import WorkModal from '../Works/WorkModal/WorkModal.js'

import sc1 from '../../assets/images/work-showcase.jpg'
import sc2 from '../../assets/images/work-showcase2.jpg'
import sc3 from '../../assets/images/work-showcase3.jpg'
import kim from '../../assets/images/kimhome.png'
import cms from '../../assets/images/cmspage.jpg'


import Slide from 'react-reveal/Slide'
import Fade from 'react-reveal/Fade'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import Scrollspy from 'react-scrollspy'
import Section from 'react-bulma-components/lib/components/section';
import Container from 'react-bulma-components/lib/components/container';
import Heading from 'react-bulma-components/lib/components/heading';
import Button from 'react-bulma-components/lib/components/button';





import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';


// import ImageGallery from 'react-image-gallery';
// import ScrollspyNav from 'react-scrollspy-nav'

// import { Breadcrumb, BreadcrumbItem } from 'reactstrap'

const prefix_url = "http://localhost:3000";

const colors = {
    Default: '',
    primary: 'primary',
    info: 'info',
    danger: 'danger',
    warning: 'warning',
    success: 'success',
    white: 'white',
    black: 'black',
    light: 'light',
    dark: 'dark',
    link: 'link',
};
class NewWorks extends React.Component {

    componentWillMount() {
        console.dir(this.props.location)
        window.scrollTo(0, 0);
        this.location = "home";
        console.log("current location: " + this.location);
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        // Add event listeners for scrolling
        window.addEventListener('wheel', this.onWheelScroll, false);
        window.addEventListener('keydown', this.onKeyDownScroll, false);
    }

    // Scrolling
    componentWillUnmount() {
        // Removes customer listener and re-enable default scrolling
        window.removeEventListener('wheel', this.onWheelScroll, false);
        window.addEventListener('keydown', this.onKeyDownScroll, false);
    }

    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
            selectedWork: {},
            CES: [
                {
                    id: 0,
                    title: 'Title of Work',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc1,
                    screenshots: [sc1, sc2, sc3]
                },
                {
                    id: 1,
                    title: 'Title of Work',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc1,
                    screenshots: [sc1, sc2, sc3]
                }
            ],
            DEH: [
                {
                    id: 0,
                    title: 'Title of Work',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc1,
                    screenshots: [sc1, sc2, sc3]
                },
                {
                    id: 1,
                    title: 'Title of Work',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc1,
                    screenshots: [sc1, sc2, sc3]
                }
            ],
            Maintain: [
                {
                    id: 0,
                    title: 'Title of Work',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc3,
                    screenshots: [sc1, sc2, sc3]
                },
                {
                    id: 1,
                    title: 'Title of Work',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc3,
                    screenshots: [sc1, sc2, sc3]
                }
            ],
            sdp: [
                {
                    id: 0,
                    title: 'Title of Work',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc1,
                    screenshots: [sc1, sc2, sc3]
                },
                {
                    id: 1,
                    title: 'SDP id 1',
                    subtitle: 'Subtitle',
                    details: 'This is details of Title of Work Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non lacus iaculis, mollis elit eget, hendrerit ex. Ut mattis enim ut nisi accumsan molestie.',
                    link: 'www.google.com',
                    year: '2019',
                    headerImage: sc3,
                    screenshots: [sc1, sc2, sc3]
                }
            ],
            GalleryHeaders: [
                {
                    name: 'CMS Logistics'
                },
                {
                    name: 'KIM'
                }
            ]
        }

        this.show = this.show.bind(this)
        this.close = this.close.bind(this)
        this.eachImage = this.eachImage.bind(this)
        this.renderModal = this.renderModal.bind(this)
        this.check = this.check.bind(this)

        /** Location */
        this.location = "home";
        console.log("Location is now " + this.location);
        // this.onWheelScroll = this.onWheelScroll.bind(this);

        /** Refs */
        this.rf_home = React.createRef();
        this.rf_solutions = React.createRef();
        this.rf_development = React.createRef();
        this.rf_maintenance = React.createRef();
    }

    check() {
        if (this.props.location.hash != null) {
            if (this.props.location.hash == "#pills-CES") {
                this.status = true;
                this.ces = " active show";
            }
            else if (this.props.location.hash == "#pills-DEH") {
                this.status = true;
                this.deh = " active show";
            }
            else if (this.props.location.hash == "#pills-Maintain") {
                this.status = true;
                this.M = " active show";
            }
            else if (this.props.location.hash == "#pills-all") {
                this.status = true;
                this.all = " active show";
            }
            else {
                this.status = false
                this.active = "";
            }
        }

    }
    arrowClick() {

    }

    show(obj) {
        this.setState({
            showModal: true,
            selectedWork: obj
        })
    }

    close() {
        this.setState({
            showModal: false,
            selectedWork: {}
        })
    }

    renderModal() {
        let obj = this.state.selectedWork
        let closeBtn = <button className="closeBtn" onClick={this.close}>Close</button>
        return (
            <WorkModal title={obj.title}
                subtitle={obj.subtitle}
                details={obj.details}
                link={obj.link}
                year={obj.year}
                headerImage={obj.headerImage}
                screenshots={obj.screenshots}
                closeBtn={closeBtn} />
        )
    }

    eachImage(obj, id) {
        return (
            <Image key={id} imgSrc={obj.headerImage} alt={obj.id}
                clickFn={() => this.show(obj)} />
        )
    }

    renderWorks() {
        this.check()
        return (
            <section id="works" className="container">
                <h1 className="text-center">
                    <span className="page-title-medium">Featured </span>
                    <span className="page-title-reg">Works</span>
                </h1>

                <ul className="nav nav-pills mb-4 d-flex justify-content-center" id="pills-tab" role="tablist">
                    <li className="nav-item">
                        <a className={'nav-link' + this.all} id="pills-all-tab" data-toggle="pill" href="#pills-all" role="tab" aria-controls="pills-all" aria-selected={this.status}>
                            All
                            </a>
                    </li>
                    <li className="nav-item">
                        <a className={'nav-link' + this.ces} id="pills-CES-tab" data-toggle="pill" href="#pills-CES" role="tab" aria-controls="pills-CES" aria-selected={this.status}>
                            Customised Enterprise Solution
                            </a>
                    </li>
                    <li className="nav-item">
                        <a className={'nav-link' + this.deh} id="pills-DEH-tab" data-toggle="pill" href="#pills-DEH" role="tab" aria-controls="pills-DEH" aria-selected={this.status}>
                            Development House
                            </a>
                    </li>
                    <li className="nav-item">
                        <a className={'nav-link' + this.M} id="pills-Maintain-tab" data-toggle="pill" href="#pills-Maintain" role="tab" aria-controls="pills-Maintain" aria-selected="false">
                            Maintenance
                            </a>
                    </li>
                    <li className="nav-item">
                        <a className={'nav-link' + this.sdp} id="pills-sdp-tab" data-toggle="pill" href="#pills-sdp" role="tab" aria-controls="pills-sdp" aria-selected="false">
                            Student Development Programme
                            </a>
                    </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                    <div className={'tab-pane fade' + this.all} id="pills-all" role="tabpanel" aria-labelledby="pills-home-all">

                        <div className="content">
                            {this.state.CES.map(this.eachImage)}
                            {this.state.DEH.map(this.eachImage)}
                            {this.state.Maintain.map(this.eachImage)}
                            {this.state.sdp.map(this.eachImage)}
                        </div>
                    </div>

                    <div className={'tab-pane fade' + this.ces} id="pills-CES" role="tabpanel" aria-labelledby="pills-CES-tab">
                        <div className="content">
                            {this.state.CES.map(this.eachImage)}
                        </div>
                    </div>

                    <div className={'tab-pane fade' + this.deh} id="pills-DEH" role="tabpanel" aria-labelledby="pills-DEH-tab">
                        <div className="content">
                            {this.state.DEH.map(this.eachImage)}
                        </div>
                    </div>

                    <div className={'tab-pane fade' + this.M} id="pills-Maintain" role="tabpanel" aria-labelledby="pills-Maintain-tab">
                        <div className="content">
                            {this.state.Maintain.map(this.eachImage)}
                        </div>
                    </div>

                    <div className="tab-pane fade" id="pills-sdp" role="tabpanel" aria-labelledby="pills-sdp-tab">
                        <div className="content">
                            {this.state.sdp.map(this.eachImage)}
                        </div>
                    </div>
                </div>
            </section>
        )
    }


    // onWheelScroll(e) {
    //     // "Re-enable" scrolling somehow
    //     if (e.deltaY < 0) { // If user scrolls up...
    //         e.preventDefault();
    //         console.log("[UP]L: " + this.location);
    //         switch(this.location) {
    //             // case "solutions":
    //             //     {
    //             //         if (ReactDOM.findDOMNode(this.refs) != null) {
    //             //             window.scrollTo(0, ReactDOM.findDOMNode(this.refs).offsetTop);
    //             //             this.location = 
    //             //         }
    //             //     }
    //             //     break;
    //             case "solutions":
    //                 {
    //                     if (ReactDOM.findDOMNode(this.rf_home.current) != null ) {
    //                         window.scrollTo(0, ReactDOM.findDOMNode(this.rf_home.current).offsetTop - 70);
    //                         this.location = "home";
    //                     }
    //                 }
    //                 break;
    //             case "development":
    //                 {
    //                     if (ReactDOM.findDOMNode(this.rf_solutions.current) != null) {
    //                         window.scrollTo(0, ReactDOM.findDOMNode(this.rf_solutions.current).offsetTop - 142);
    //                         this.location = "solutions"
    //                         console.log("-> " + this.location);
    //                     }
    //                 }
    //                 break;
    //             case "maintenance":
    //                 {
    //                     if (ReactDOM.findDOMNode(this.rf_development.current) != null) {
    //                         window.scrollTo(0, ReactDOM.findDOMNode(this.rf_development.current).offsetTop - 142);
    //                         this.location = "development";
    //                         console.log("-> " + this.location);
    //                     }
    //                 }
    //                 break;
    //             default:
    //                 {
    //                     this.location = "home";
    //                     window.scrollTo(0, 0);
    //                     console.log("-> " + this.location);
    //                 }
    //         }
    //     } else if (e.deltaY > 0) { // If user scrolls down
    //         e.preventDefault();
    //         console.log("[DOWN] L: " + this.location);
    //         switch(this.location) {
    //             case "home":
    //                 {
    //                     if (ReactDOM.findDOMNode(this.rf_solutions.current) != null) {
    //                         window.scrollTo(0, ReactDOM.findDOMNode(this.rf_solutions.current).offsetTop - 142);
    //                         this.location = "solutions";
    //                         console.log("-> " + this.location);
    //                     }
    //                 }
    //                 break;
    //             case "solutions":
    //                 {
    //                     // console.log("case3. scrolling down. state is " + this.location);
    //                     if (ReactDOM.findDOMNode(this.rf_development.current) != null) {
    //                         window.scrollTo(0, ReactDOM.findDOMNode(this.rf_development.current).offsetTop - 142);
    //                         this.location = "development";
    //                         console.log("-> " + this.location);
    //                     }
    //                 }
    //                 break;
    //             case "development":
    //                 {
    //                     if (ReactDOM.findDOMNode(this.rf_maintenance.current) != null) {
    //                         window.scrollTo(0, ReactDOM.findDOMNode(this.rf_maintenance.current).offsetTop - 142);
    //                         this.location = "maintenance";
    //                         console.log("-> " + this.location);
    //                     }
    //                 }
    //                 break;
    //             default:
    //                 {
    //                     // this.location
    //                     // console.log("case 4");
    //                 }
    //         }
    //     }
    // }

    renderItem = (item) => {
        return (
            <div className="image-gallery-image" >
                <img
                    src={item.original}
                    alt={item.originalAlt}

                />


                <div
                    className="image-gallery-description"
                    dangerouslySetInnerHTML={{
                        __html: item.description
                    }}
                />
                <div className="image-gallery-button">
                    <a class="button is-dark is-hovered is-rounded is-normal " href={item.pageURL}>See More</a>
                </div>
            </div>

        );
    };




    render() {
        const scrollWithOffset = (el, offset) => {
            const elementPosition = el.offsetTop - offset;
            window.scroll({
                top: elementPosition,
                left: 0,
                behavior: "smooth"

            })
        }
        const images = [
            {
                original: `${prefix_url}${kim}`,
                thumbnail: `${prefix_url}${kim}`,
                description: 'KIM',
                pageURL: "/kimsusecase"



            },
            // {
            //     original: `${prefix_url}${cms}`,
            //     thumbnail: `${prefix_url}${cms}`,
            //     description: 'CMS Logistics',
            //     pageURL: "/cmsusecase"


            // },


        ]

        return (

            <div id="works-wrapper1">
                {/* <div style={{background: "#000"}}>
                    <Breadcrumb id="breadcrumb" className="fixed-top">
                        <BreadcrumbItem><a href="#">Home</a></BreadcrumbItem>
                        <BreadcrumbItem active>Works</BreadcrumbItem>
                    </Breadcrumb>
                </div> */}

                <div id="wk_header_wrapper" ref={this.rf_home}>
                    <Fade cascade>
                        <div id="wk_header">
                            <h1>OUR WORKS</h1>
                            <p>
                                We’re proud to have worked with both startups looking to change
                                the world and enterprises embracing technological innovation.
                            </p>
                        </div>
                    </Fade>
                </div>




                {/*Works 2*/}
                <section id="works" ref={this.rf_solutions}>
                    <div className="container" id="solutions">

                        {/* <Fade left>
                            <p id="paragraph1">{this.state.GalleryHeaders[0].name}</p>
                        </Fade> */}


                        <ImageGallery id="imagegallery1" renderItem={this.renderItem} items={images} autoPlay={true} slideInterval={5000} />


                    </div>
                    <div>

                    </div>
                </section>

            </div >
        )
    }


}


export default NewWorks