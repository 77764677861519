import React from 'react'
import Zoom from 'react-reveal/Zoom'
import Fade from 'react-reveal/Fade'
import './Image.css'
import '../../../assets/images/work-showcase.jpg'

class Image extends React.Component {
    render() {
        return (
            <Fade>
                <div onClick={this.props.clickFn}>
                    <p>Title of Project</p>
                    <img src={this.props.imgSrc} className="img-fluid workImg" alt={this.props.alt} />
                </div>
            </Fade>
        )
    }
}

export default Image